import React, { Component } from 'react';
import { Tools } from '../../tools';
import Question from '../../models/questions';
import User from '../../models/user';

import CheckOffIcon from '../../../assets/icons/check-off-icon.svg';
import CheckOnIcon from '../../../assets/icons/check-on-icon.svg';

class QuestionType extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;
        this.continue = this.continue.bind(this);
    }

    continue(item, e) {
        const { from, question } = this.props;
        e.preventDefault();

        let _root = this;
        let _target = e.target;

        if(from) {
            if(from == 'per' || from == 'psi') {
                let _desks = JSON.parse(Tools.decryptrData(localStorage.getItem('desktop_selected_items')));
                for(let _q of _desks) {
                    if(_q.selected) {
                        if(_q.selected == true && (_q.answers.length < 10)) {
                            let _arr = _q.answers;
                            this.saveQuestionResult(item, question);
                            _arr.push(item);
                            _q.answers = _arr;
                        }
                    }
                }

                localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify(_desks) ));

                if( _target.getAttribute('href') == URLPREFIX + '/step-twentyone' ) {
                    let _ds = JSON.parse(Tools.decryptrData(localStorage.getItem('desktop_selected_items')));
                    let _st = [];

                    for(let si of _ds) {
                        if(si.selected && (si.selected == true)) {
                            _st.push(si.selected);
                        }
                    }

                    if(_ds.length == _st.length) {
                        window.location.href = URLPREFIX + '/step-thirtythree';
                    }
                    else {
                        window.location.href = URLPREFIX + '/step-nine';
                    }
                }
                else {
                    if( e.target.getAttribute('href') ) {
                        window.location.href = e.target.getAttribute('href');
                    }
                    else {
                        if( e.target.parentNode.parentNode.getAttribute('href') ) {
                            window.location.href = e.target.parentNode.parentNode.getAttribute('href');
                        }
                    }
                }
            }
        }
        else {
            if( e.target.getAttribute('href') ) {
                this.saveQuestionResult(item, question);
                _root.QS.saveOptionAnswerQuestionProfile(item);
                window.location.href = e.target.getAttribute('href');
            }
            else {
                if( e.target.parentNode.parentNode.getAttribute('href') ) {
                    window.location.href = e.target.parentNode.parentNode.getAttribute('href');
                }
            }
        }
    }

    saveQuestionResult(item, question) {
        let _itemanswer = {
            question_id: question.id,
            title: question.title,
            options: [
                {
                    id: item.id
                }
            ]
        };

        let _answer_items_tosend = JSON.parse( Tools.decryptrData( localStorage.getItem('answer_items_tosend') ) );
        _answer_items_tosend.push(_itemanswer);
        localStorage.setItem('answer_items_tosend', Tools.cryptrData( JSON.stringify(_answer_items_tosend) ));
    }

    render() {
        const { type, question, next_path } = this.props;

        let _html = '';

        if( type == '11' ) {
            if(question.images && (question.images.length > 0)) {
                let _urlexternal = Tools.extractProtocol(question.images[0].src) + '://' + Tools.extractHostname(question.images[0].src);
                question.images[0].src = (question.images[0].src).replace(_urlexternal, URL);
                if( Tools.extractProtocol(question.images[0].src) != 'https' ){question.images[0].src.replace('http', 'https');}
            }

            _html = (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-12 col-md-8">
                        {
                            (question.title)
                            ?
                            <h1 className="title-style-3 pl-5 pr-5">{question.title.replace(/\xA0/g,' ')}</h1>
                            :
                                ''
                        }
                    </div>
                    {
                        (question.images && (question.images.length > 0))
                        ?
                        <div className="col-sm-12 col-md-5 image-responsive">
                            <img src={question.images[0].src} />
                        </div>
                        :
                        ''
                    }
                </div>

                <div className="row justify-content-center mt-5">
                    <div className="col-sm-12 col-md-6">
                        <div className="btn-group pl-3 pr-3 style-1" role="group">
                        {
                            (question.options)
                            ?
                                (question.options.length > 0)
                                ?
                                    question.options.map((item, i) => (
                                    <a key={i} href={next_path} className="btn btn-danger mr-2 pt-3 pb-3 btn-style-2" onClick={this.continue.bind(this, item)}>{item.title.replace(/\xA0/g,' ')}</a>
                                    ))
                                :
                                    ''
                            :
                                ''
                        }
                        </div>
                    </div>
                </div>
            </div>
            )
        }

        else if(type == '1') {
            if(question.images && (question.images.length > 0)) {
                let _urlexternal = Tools.extractProtocol(question.images[0].src) + '://' + Tools.extractHostname(question.images[0].src);
                question.images[0].src = (question.images[0].src).replace(_urlexternal, URL);
                if( Tools.extractProtocol(question.images[0].src) != 'https' ){question.images[0].src.replace('http', 'https');}
            }
            _html = (
                <div className="container style-1">
                    <div className="col-sm-12 col-md-6">
                        <div className="row justify-content-center">
                            <div className="col-sm-12">
                                <h1 className="title-style-4 text-show-responsive pl-5 pr-5">{question.title.replace(/\xA0/g,' ')}</h1>
                            </div>
                            {
                                (question.images && (question.images.length > 0))
                                ?
                                <div className="col-sm-12 number-order image-responsive">
                                    <img src={question.images[0].src} />
                                </div>
                                :
                                ''
                            }
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6">
                        <div className="row justify-content-center">
                            <div className="col-sm-12">
                                <h1 className="title-style-4 text-hide-responsive pl-5 pr-5">{question.title.replace(/\xA0/g,' ')}</h1>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-sm-12 col-md-8">
                                <div className="btn-group pl-3 pr-3 style-2" role="group">
                                    {
                                        (question.options)
                                        ?
                                            (question.options.length > 0)
                                            ?
                                                question.options.map((item, i) => (
                                                <a key={i} href={next_path} className="btn mb-4 pl-5 pt-3 pb-3 btn-style-4">
                                                    <span className="check-off"><img src={URLPREFIX + CheckOffIcon} /></span>
                                                    <span className="check-on"><img src={URLPREFIX + CheckOnIcon} /></span>
                                                    {item.title.replace(/\xA0/g,' ')}
                                                </a>
                                                ))
                                            :
                                                ''
                                        :
                                            ''
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        else if(type == '2') {
            _html = (
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-8">
                            {
                                (question.title)
                                ?
                                <h1 className="title-style-4 pl-5 pr-5">{question.title.replace(/\xA0/g,' ')}</h1>
                                :
                                ''
                            }
                        </div>
                    </div>

                    <div className="row justify-content-center mt-2">
                        <div className="col-sm-12 col-md-4">
                            <div className="btn-group pl-3 pr-3 style-2" role="group">
                            {
                                (question.options)
                                ?
                                    (question.options.length > 0)
                                    ?
                                        question.options.map((item, i) => (
                                        <a key={i} href={next_path} className="btn mb-4 pt-3 pb-3 btn-style-4" onClick={this.continue.bind(this, item)}>
                                            <span className="check-off"><img src={URLPREFIX + CheckOffIcon} /></span>
                                            <span className="check-on"><img src={URLPREFIX + CheckOnIcon} /></span>
                                            {item.title.replace(/\xA0/g,' ')}</a>
                                        ))
                                    :
                                        ''
                                :
                                    ''
                            }
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        else if(type == '18') {
            if(question.images && (question.images.length > 0)) {
                let _urlexternal = Tools.extractProtocol(question.images[0].src) + '://' + Tools.extractHostname(question.images[0].src);
                question.images[0].src = (question.images[0].src).replace(_urlexternal, URL);
                if( Tools.extractProtocol(question.images[0].src) != 'https' ){question.images[0].src.replace('http', 'https');}
            }

            if(question.options.length > 0) {
                for(let item of question.options) {
                    if(item.images && (item.images.length > 0)){
                        let _urlexternal = Tools.extractProtocol(item.images[0].src) + '://' + Tools.extractHostname(item.images[0].src);
                        item.images[0].src = (item.images[0].src).replace(_urlexternal, URL);
                        if( Tools.extractProtocol(item.images[0].src) != 'https' ){item.images[0].src.replace('http', 'https');}
                    }
                }
            }
            
            let _class_container = 'col-sm-8';
            let _class = 'col-sm-6 cubos';
            if(question.options.length > 0) {
                if(question.options.length == 1) {
                    _class = 'col-sm-6 cubos four';
                    _class_container = 'col-sm-8';
                }
                if(question.options.length == 2) {
                    _class = 'col-sm-6 cubos four';
                    _class_container = 'col-sm-8';
                }
                if(question.options.length == 3) {
                    _class = 'col-sm-6 cubos four';
                    _class_container = 'col-sm-8';
                }
                if(question.options.length == 4) {
                    _class = 'col-sm-6 cubos four';
                    _class_container = 'col-sm-8';
                }
                if(question.options.length == 5) {
                    _class = 'col-sm-4 cubos';
                    _class_container = 'col-sm-12';
                }
                if(question.options.length == 6) {
                    _class = 'col-sm-4 cubos';
                    _class_container = 'col-sm-12';
                }
            }
            _html = (
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-sm-12">
                                    
                                    {
                                        (question.title)
                                        ?
                                        <h1 className="title-style-4 pl-5 pr-5">{question.title.replace(/\xA0/g,' ')}</h1>
                                        :
                                            ''
                                    }
                                </div>
                            </div>

                            <div className="row justify-content-center mt-2">
                                <div className="col-sm-12 col-md-8 image-responsive">
                                    {
                                        (question.images && (question.images.length > 0))
                                        ?
                                        <img src={question.images[0].src} />
                                        :
                                        ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className={_class_container}>
                                    <div className="btn-group style-1" role="group">
                                        <div className="container">
                                            <div className="row">
                                            {
                                                (question.options)
                                                ?
                                                    (question.options.length > 0)
                                                    ?
                                                        question.options.map((item, i) => (
                                                        
                                                        <div key={i} className={_class}>
                                                            {
                                                                (item.images && (item.images.length > 0))
                                                                ?
                                                                <img className="mb-2" src={item.images[0].src} />
                                                                :
                                                                ''
                                                            }
                                                            <a href={next_path} className="btn btn-danger btn-lg btn-block btn-style-2" onClick={this.continue.bind(this, item)}>{item.title.replace(/\xA0/g,' ')}</a>
                                                        </div>
                                                        ))
                                                    :
                                                        ''
                                                :
                                                    ''
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className="q-panel">
            {_html}
            </div>
        )
    }

}

export default QuestionType;

/*
TYPES ID:
========
*/
// 11: Si / No
// 2: RadioButton
// 1: RadioButton / Imagen Izquierda
// 18: Gráfico