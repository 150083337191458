import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import CheckOffIcon from '../../../assets/icons/check-off-icon.svg';
import CheckOnIcon from '../../../assets/icons/check-on-icon.svg';

import EmocioCara from '../../../assets/images/emocion-cara.png';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepTwentyFive extends Component {

    componentDidMount() {
        document.title = "StepTwentyFive";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="container style-1">
                        <div className="col-sm-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-sm-12">
                                    <h1 className="title-style-4 text-show-responsive pl-5 pr-5">¿Què emoción tiene esta cara?</h1>
                                </div>
                                <div className="col-sm-12 pt-4 pb-4" style={{textAlign: 'center'}}>
                                    <img style={{width: '70%'}} src={EmocioCara} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6">
                            <div className="row justify-content-center">
                                <div className="col-sm-12">
                                    <h1 className="title-style-4 text-hide-responsive pl-5 pr-5">¿Què emoción tiene esta cara?</h1>
                                </div>
                            </div>

                            <div className="row justify-content-center mt-2">
                                <div className="col-sm-12 col-md-8">
                                    <div className="btn-group pl-3 pr-3 style-2" role="group">
                                        <a href="/step-twentysix" className="btn mb-4 pl-5 pt-3 pb-3 btn-style-4">
                                            <span className="check-off"><img src={CheckOffIcon} /></span>
                                            <span className="check-on"><img src={CheckOnIcon} /></span>
                                            Molesto</a>
                                        <a href="/step-twentysix" className="btn mb-4 pl-5 pt-3 pb-3 btn-style-4">
                                            <span className="check-off"><img src={CheckOffIcon} /></span>
                                            <span className="check-on"><img src={CheckOnIcon} /></span>
                                            Triste</a>
                                        <a href="/step-twentysix" className="btn pl-5 mb-4 pt-3 pb-3 btn-style-4">
                                            <span className="check-off"><img src={CheckOffIcon} /></span>
                                            <span className="check-on"><img src={CheckOnIcon} /></span>
                                            Nervioso</a>
                                        <a href="/step-twentysix" className="btn pl-5 mb-4 pt-3 pb-3 btn-style-4">
                                            <span className="check-off"><img src={CheckOffIcon} /></span>
                                            <span className="check-on"><img src={CheckOnIcon} /></span>
                                            Alegre</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepTwentyFive;