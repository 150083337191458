import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import SigueSec1 from '../../../assets/images/sigue-secuencia/sigue-sec-1.svg';
import SigueSec2 from '../../../assets/images/sigue-secuencia/sigue-sec-2.svg';
import SigueSec3 from '../../../assets/images/sigue-secuencia/sigue-sec-3.svg';
import SigueSec4 from '../../../assets/images/sigue-secuencia/sigue-sec-4.svg';
import SigueSec5 from '../../../assets/images/sigue-secuencia/sigue-sec-5.svg';
import SigueSec6 from '../../../assets/images/sigue-secuencia/sigue-sec-6.svg';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepTwentyNine extends Component {

    componentDidMount() {
        document.title = "StepTwentyNine";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-7">
                                <h1 className="title-style-4 pl-5 pr-5">Sigue la secuencia</h1>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="row justify-content-center mt-3 mb-4">
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec1} />
                                        <h3>1</h3>
                                    </div>
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec2} />
                                        <h3>2</h3>
                                    </div>
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec3} />
                                        <h3>4</h3>
                                    </div>
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec4} />
                                        <h3>8</h3>
                                    </div>
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec5} />
                                        <h3>16</h3>
                                    </div>
                                    <div className="col-sm-12 col-md-4 secuencia mb-2">
                                        <img src={SigueSec6} />
                                        <h3>¿?</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-2">
                            <div className="col-sm-12 col-md-4">
                                <div className="btn-group pl-3 pr-3 style-1 style-1-responsive" role="group">
                                    <a href="/step-thirty" className="btn btn-danger mr-2 pt-3 pb-3 btn-style-2">20</a>
                                    <a href="/step-thirty" className="btn btn-danger ml-3 pt-3 pb-3 btn-style-2">31</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepTwentyNine;