import axios from "axios";
import { APIS } from "../config_apis";
import { Tools } from "../tools";

class User {

    register(data, token) {
        let response = '';
        return axios({
            method: 'POST',
            url: `${APIS.user_register}`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            },
            data: data
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    checkUserId() {
        let uid = Tools.decryptrData(localStorage.getItem('ur'));
        if(uid == '') {
            window.location.href = '/step-one';
        }
    }

    getUserId() {
        return Number( Tools.decryptrData(localStorage.getItem('ur')) );
    }

}

export default User;