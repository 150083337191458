import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import Question from '../../models/questions';
import { Tools } from '../../tools';

import ReactPixel from 'react-facebook-pixel';

// COMPONENTS
import Header from '../../components/Header';

import LogoVisualizate from '../../../assets/images/visualizate-utp-logo.svg';
import BgMain from '../../../assets/images/bg-main.png';

import Recuerda1 from '../../../assets/icons/recuerda-1-icon.svg';
import Recuerda2 from '../../../assets/icons/recuerda-2-icon.svg';

class PageHome extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;

        this.state = {
            crr_count: 0,
            next_active: false,
            configuration: [],

            // assets view
            logo: '',
            image_view: '',
            url_vars: '',

            // slide
            slides: '3',
            current_slide: ''
        }

        this.crr_count = 0;
        
        this.options = {
            items: 1,
            nav: false,
            rewind: false,
            autoplay: true,
            responsive : {
                0 : {
                    items: 1,
                },
                480 : {
                    items: 1,
                },
                800 : {
                    items: 1,
                }
            }
        };

        this.events = {
            onChanged: this.onChangedCarrousel
        }
    }

    onChangedCarrousel = e => {
        if( e.item.index ) {
            let _index_owl = e.item.index;
            if( _index_owl > 1 ) {
            }
        }
    }

    componentDidMount() {
        const advancedMatching = {};
        const options = {
            autoConfig: false,
            debug: false,
        };
        ReactPixel.init('1850346191885647', advancedMatching, options);
        ReactPixel.pageView();
        ReactPixel.fbq('track', 'CompleteRegistration');

        Tools.showPreloader();
        localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('profiles_messages', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('answer_items_tosend', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('desktop_selected_items_vocation', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('profiles', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('other_profiles', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('personalidad', Tools.cryptrData(JSON.stringify([])));
        localStorage.setItem('psicotecnicas', Tools.cryptrData(JSON.stringify([])));
        localStorage.setItem('profiles_answer', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('desktops', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('campus', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('campus_selected', Tools.cryptrData(''));
        localStorage.setItem('ur', Tools.cryptrData(''));
        localStorage.setItem('un', Tools.cryptrData(''));
        localStorage.setItem('ia', Tools.cryptrData(''));
        localStorage.setItem('aps', Tools.cryptrData(''));
        document.title = "Bienvenido a Visualizate";
        
        let _root = this;

        _root.setState({
            url_vars: Tools.getAllUrlVars()
        });

        _root.QS.getToken()
        .then(res => {
            _root.QS.setTokenToLocal(res);
            return _root.QS.getConfiguration();
        })
        .then(res => {
            let _urlexternal = Tools.extractProtocol(res[0].field_logo[0].url) + '://' + Tools.extractHostname(res[0].field_logo[0].url);
            res[0].field_logo[0].url = (res[0].field_logo[0].url).replace(_urlexternal, URL);
            if( Tools.extractProtocol(res[0].field_logo[0].url) != 'https' ){res[0].field_logo[0].url.replace('http', 'https');}

            for(let i = 0; i < res[0].field_imagenes_vistas.length; i++) {
                res[0].field_imagenes_vistas[i].url = (res[0].field_imagenes_vistas[i].url).replace(_urlexternal, URL);
                if( Tools.extractProtocol(res[0].field_imagenes_vistas[i].url) != 'https' ){res[0].field_imagenes_vistas[i].url.replace('http', 'https');}
            }

            _root.setState({
                configuration: res
            });

            if( res[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: res[0].field_logo[0].url
                });
            }

            if( res[0].field_imagenes_vistas.length > 0 ) {
                for( let item of res[0].field_imagenes_vistas ) {
                    if( item.alt == 'view-home' ) {
                        _root.setState({
                            image_view: item.url
                        });
                    }
                }
            }

            _root.QS.setConfigurationToLocal(res);
            return _root.QS.getQuestionGroupList('56', _root.QS.getTokenFromLocal());
        })
        .then(res => {
            for(let pt of res) {
                if(pt.id == '53') {
                    let profiles = _root.QS.buidlProfileQuestions(pt);
                    if(profiles.length > 0) {
                        for(let p of profiles) {
                            if(p.questions.length > 0) {
                                let _pm = JSON.parse( Tools.decryptrData( localStorage.getItem('profiles_messages') ) );
                                _pm.push( p.questions[0].profile );
                                localStorage.setItem('profiles_messages', Tools.cryptrData( JSON.stringify(_pm) ));
                            }

                            for(let q of p.questions) {
                                
                                q.type = pt.id;
                                for(let o of q.options) {
                                    o.profile = q.profile;
                                }
                            }
                        }
                        _root.QS.setProfilesToLocal(profiles);
                    }
                }
                else if(pt.id == '54') {
                    let personalidad = _root.QS.buidlProfileQuestions(pt);
                    if(personalidad.length > 0) {
                        for(let p of personalidad) {
                            for(let q of p.questions) {
                                q.type = pt.id;
                                for(let o of q.options) {
                                    o.profile = q.profile;
                                }
                            }
                        }
                        localStorage.setItem('personalidad', Tools.cryptrData(JSON.stringify(personalidad)));
                    }
                }
                else if(pt.id == '55') {
                    let psicotecnicas = _root.QS.buidlProfileQuestions(pt);
                    if(psicotecnicas.length > 0) {
                        for(let p of psicotecnicas) {
                            for(let q of p.questions) {
                                q.type = pt.id;
                                for(let o of q.options) {
                                    o.profile = q.profile;
                                }
                            }
                        }
                        localStorage.setItem('psicotecnicas', Tools.cryptrData(JSON.stringify(psicotecnicas)));
                    }
                }
                else if(pt.id == '131') {
                    let escritorios = [];
                    for(let item of _root.QS.buidlProfileQuestions(pt)) {
                        if( item.questions.length > 0 ) {
                            for(let o of item.questions[0].options) {
                                o.profile = item.questions[0].profile;
                            }
                            escritorios.push(item.questions[0]);
                        }
                    }
                    localStorage.setItem('desktops', Tools.cryptrData(JSON.stringify(escritorios)));
                }
            }

            return _root.QS.getCampusList();
        })
        .then(res => {
            _root.QS.setCampusToLocal(res);

            setTimeout(() => {
                Tools.hidePreloader();
            }, 1000);
        })
        .catch(err => {
            console.log( err );
        });
    }

    render() {
        const {configuration, logo, image_view, url_vars, slides, current_slide} = this.state;
        const events = {
            onChanged: function(e) {
                if( e.item.index ) {
                    if( e.item.index > (e.item.count - 2) ) {
                        document.getElementById('init-test-btn').classList.add('active');
                    }
                }
            }
        }

        return (
            <div>
                {
                    ( configuration.length > 0 )
                    ?
                    <div>
                        <Header logo={logo}></Header>
                        <div className="main-container" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                            <div className="container">
                                <div className="row carrusel">
                                    <div className="col-sm-12 col-md-7 image-view">
                                        <img className="img-fluid" src={image_view} />
                                    </div>
                                    <div className="col-sm-12 col-md-4 offset-md-1">
                                        <OwlCarousel options={this.options} events={events} >
                                            <div className="item">
                                                <h1 className="title-style-1-1 mt-5 mb-2">Bienvenido a</h1>
                                                <p className="parra-style-3">
                                                    <img src={URLPREFIX + LogoVisualizate} />
                                                    <br/>
                                                    Hoy pasarás por preguntas y retos que te ayudarán a encontrar tu vocación
                                                </p>
                                                <p className="count-slide">1 de {slides}</p>
                                            </div>
                                            <div className="item">
                                                <h1 className="title-style-1 mt-5 mb-2">Recuerda</h1>
                                                <p className="parra-style-4">
                                                    <span className="icon mr-4">
                                                        <img src={URLPREFIX + Recuerda1} />
                                                    </span>
                                                    Se sincero(a) y expontáneo(a) en tus respuestas, sin reflexionar demasiado en lo que se "espera" de ti, sino en lo que realmente te atrae.
                                                </p>
                                                <p className="count-slide">2 de {slides}</p>
                                            </div>
                                            <div className="item">
                                                <h1 className="title-style-1 mt-5 mb-2">Recuerda</h1>
                                                <p className="parra-style-4">
                                                    <span className="icon mr-4">
                                                        <img src={URLPREFIX + Recuerda2} />
                                                    </span>
                                                    Si cierras la partida o retrocedes, perderás lo avanzado.
                                                </p>
                                                <p className="count-slide">3 de {slides}</p>
                                            </div>
                                        </OwlCarousel>
                                        
                                        <a id="init-test-btn" href={URLPREFIX + '/step-one' + url_vars} type="button" className="btn btn-danger mt-5 pt-3 pb-3 btn-style-1-1">¡Estoy listo!</a>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    ''
                }
            </div>
        )
    }
}

export default PageHome;