import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import Student01 from '../../../assets/images/student-01.png';
import DesktopImageSelect from '../../../assets/images/desktop-image-select.png';

import desktop_1 from '../../../assets/images/desktop-images/desktop-1.png';
import desktop_2 from '../../../assets/images/desktop-images/desktop-2.png';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepEightteen extends Component {

    constructor(props) {
        super(props);

        this.state = {
            desktop_img_selected: 0
        }
    }

    componentDidMount() {
        document.title = "StepEightteen";
    }

    onClickDeskTopImage = e => {
        e.preventDefault();

        if( this.state.desktop_img_selected < 1 ) {
            if( e.currentTarget ) {
                if( !e.currentTarget.classList.contains('active') ) {
                    e.currentTarget.classList.add('active');
                    this.setState({
                        desktop_img_selected: (this.state.desktop_img_selected + 1)
                    });
                }
            }
        }
        else {
            for(let item of document.querySelectorAll('.desktop-image')) {
                item.classList.remove('active');
            }
            if( e.currentTarget ) {
                e.currentTarget.classList.add('active');
                this.setState({
                    desktop_img_selected: 1
                });
            }
        }
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container container-responsive mt-5">
                        <div className="row justify-content-center align-items-center row-responsive mt-5">
                            <div className="col-sm-6">
                                <h1 className="title-style-2">¡Estas a la mitad de visualizar tu profesión!</h1>
                                <p className="parra-style-2 pl-2 pr-2">Completa la siguiente isla y descúbrela.</p>
                                <h1 className="title-style-4 pl-5 pr-5">Selecciona una:</h1>
                            </div>
                            <div className="col-sm-12">
                                <div className="row justify-content-center">
                                    <div className="col-sm-4 pt-3 pb-3 desktop-image style-1" onClick={this.onClickDeskTopImage}>
                                        <img src={desktop_1} className="img-fluid" />
                                        <img src={DesktopImageSelect} className="img-fluid select" />
                                        <p className="parra-style-2 mt-2">Investigador</p>
                                    </div>
                                    <div className="col-sm-4 pt-3 pb-3 desktop-image style-1" onClick={this.onClickDeskTopImage}>
                                        <img src={desktop_2} className="img-fluid" />
                                        <img src={DesktopImageSelect} className="img-fluid select" />
                                        <p className="parra-style-2 mt-2">Convencional</p>
                                    </div>
                                    <div className="col-sm-12 pt-3 pb-3 btn-container-1">
                                        <a href="/step-nineteen" className="btn btn-danger pt-3 pb-3 btn-style-3" style={{float: 'right', width: '20%'}}>Siguiente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepEightteen;