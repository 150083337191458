import React, { Component } from 'react';
import { Tools } from '../../tools';
import Question from '../../models/questions';
import User from '../../models/user';

import BgMain from '../../../assets/images/bg-main-2.png';

import DesktopImageSelect from '../../../assets/images/desktop-image-select.png';

class PageStepNine extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;

        this.continue = this.continue.bind(this);
        this.onClickDeskTopImage = this.onClickDeskTopImage.bind(this);

        this.state = {
            desktop_img_selected: 0,
            images_escritorios: [],
            configuration: [],

            // assets view
            logo: ''
        }
    }

    componentDidMount() {
        localStorage.setItem('desktop_selected_items_vocation', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('other_profiles', Tools.cryptrData( JSON.stringify([]) ));
        document.title = "StepNine";
        let _root = this;

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ) );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }
        });

        if( localStorage.getItem('desktop_selected_items') ) {
            let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items')) );
            _root.setState({
                images_escritorios: _dsi
            });
        }
    }

    onClickDeskTopImage(item, e) {
        e.preventDefault();

        let _root = this;
        let count_selected = 1;

        if( _root.state.images_escritorios.length == 1 ) {
            count_selected = 1;
        }
        else if( _root.state.images_escritorios.length > 1) {
            count_selected = 1;
        }
            if( e.currentTarget ) {
                if( !e.currentTarget.classList.contains('active') ) {
                    if(_root.state.desktop_img_selected < count_selected) {
                        e.currentTarget.classList.add('active');
                        _root.setState({
                            desktop_img_selected: (_root.state.desktop_img_selected + 1)
                        }, function() {
                            if( localStorage.getItem('desktop_selected_items_vocation') ) {
                                let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items_vocation')) );
                                _dsi.push(item);
                                localStorage.setItem('desktop_selected_items_vocation', Tools.cryptrData( JSON.stringify(_dsi) ));
                            }

                            if(_root.state.desktop_img_selected == count_selected) {
                                document.getElementById('next-btn').classList.remove('inactive');
                                document.getElementById('next-btn').classList.add('active');
                            }
                        });
                    }
                }
                else {
                    document.getElementById('next-btn').classList.remove('active');
                    document.getElementById('next-btn').classList.add('inactive');

                    e.currentTarget.classList.remove('active');
                    _root.setState({
                        desktop_img_selected: (_root.state.desktop_img_selected - 1)
                    }, function() {
                        if( localStorage.getItem('desktop_selected_items_vocation') ) {
                            let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items_vocation')) );
                            _dsi = _dsi.filter(function(it) {
                                return it.id !== item.id
                            });
                            localStorage.setItem('desktop_selected_items_vocation', Tools.cryptrData( JSON.stringify(_dsi) ));
                        }
                    });
                }
            }
        
    }

    continue(e) {
        e.preventDefault();

        let _root = this;
        let _target = e.target;

        if( localStorage.getItem('desktop_selected_items_vocation') ) {
            let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items_vocation')) );
            let _questions = [];
            let _op = [];

            for(let pr of JSON.parse(Tools.decryptrData(localStorage.getItem('personalidad')))) {
                for(let q of pr.questions) {
                    _questions.push(q);
                }
            }
            for(let ps of JSON.parse(Tools.decryptrData(localStorage.getItem('psicotecnicas')))) {
                for(let q of ps.questions) {
                    _questions.push(q);
                }
            }

            for(let item of _dsi) {
                for(let q of _questions) {
                    if(item.profile.id == q.profile.id) {
                        _op.push(q);
                    }
                }

                for(let d of _root.state.images_escritorios) {
                    if(item.id == d.id) {
                        d.selected = true;
                        d.answers = [];
                    }
                }
            }

            _root.setState({
                images_escritorios: _root.state.images_escritorios
            }, function() {
                localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify(_root.state.images_escritorios) ));
                localStorage.setItem('other_profiles', Tools.cryptrData( JSON.stringify(_op) ));
                window.location.href = _target.getAttribute('href');
            });
        }
    }

    render() {
        const {images_escritorios, logo} = this.state;

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container container-responsive mt-5">
                        <div className="row justify-content-center align-items-center row-responsive mt-5">
                            <div className="col-sm-6">
                                <h1 className="title-style-2">Ahora empezaremos a visualizar tu vocación</h1>
                                <p className="parra-style-2 pl-2 pr-2">Las herramientas que estaban en tu escritorio te darán los conocimientos que te ayudarán a visualizarte.</p>
                                
                                {
                                    (images_escritorios.length > 0)
                                    ?
                                        (images_escritorios.length == 1)
                                        ?
                                        <h1 className="title-style-4 pl-5 pr-5">Esta es tu opción:</h1>
                                        :
                                        <h1 className="title-style-4 pl-5 pr-5">Selecciona una:</h1>
                                    :
                                    ''
                                }
                            </div>
                            <div className="col-sm-12">
                                <div className="row justify-content-center">
                                    {
                                        (images_escritorios.length > 0)
                                        ?
                                            images_escritorios.map((item, i) => (
                                                (item.selected)
                                                ?
                                                <div  key={i} className="col-sm-4 pt-3 pb-3 desktop-image-selected style-1 active">
                                                    <img src={item.images[0].src} className="img-fluid" />
                                                    <img src={URLPREFIX + DesktopImageSelect} className="img-fluid select" />
                                                    <p className="parra-style-2 mt-2">{item.profile.title}</p>
                                                </div>
                                                :
                                                <div  key={i} className="col-sm-4 pt-3 pb-3 desktop-image style-1" onClick={this.onClickDeskTopImage.bind(this, item)}>
                                                    <img src={item.images[0].src} className="img-fluid" />
                                                    <img src={URLPREFIX + DesktopImageSelect} className="img-fluid select" />
                                                    <p className="parra-style-2 mt-2">{item.profile.title}</p>
                                                </div>
                                            ))
                                        :
                                            ''
                                    }
                                    <div className="col-sm-12 pt-3 pb-3 btn-container-1">
                                        <a id="next-btn" href={URLPREFIX + '/step-ten'} className="btn btn-danger pt-3 pb-3 btn-style-3 inactive" style={{float: 'right', width: '20%'}} onClick={this.continue.bind(this)}>Siguiente</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepNine;