import React, { Component } from 'react';
import Select from 'react-select-v2';
import Question from '../../models/questions';
import User from '../../models/user';

// COMPONENTS
import {Button, Modal } from 'react-bootstrap';
import Header from '../../components/Header';

import { Tools } from '../../tools';

import BgMain from '../../../assets/images/bg-main.png';
const leadRegitser = async (dataRegister, destino )=>{

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("codSede", dataRegister.campus);
    urlencoded.append("nombres", dataRegister.first_name);
    urlencoded.append("apellidos", dataRegister.last_name);
    urlencoded.append("celular", dataRegister.phone);
    urlencoded.append("ID", "");
    urlencoded.append("IdNivelAcademico", dataRegister.nivel_estudios);
    urlencoded.append("correo", dataRegister.email);
    urlencoded.append("utm_2", Tools.getUTM('utm_medium').length > 1 ? Tools.getUTM('utm_medium'):'CPC');
    urlencoded.append("utm_3", Tools.getUTM('utm_campaign'));
    urlencoded.append("utm_1",  Tools.getUTM('utm_detallefuente' ));
    urlencoded.append("FuenteOrigen", Tools.getUTM('utm_fuenteorigen').length > 1 ? Tools.getUTM('utm_fuenteorigen'):'web');
    urlencoded.append("IdPromotor", dataRegister.select_grupo_colegio.id.split('|')[0]);
    urlencoded.append("IdColegioCRM", dataRegister.select_grupo_colegio.id.split('|')[1]);
    urlencoded.append("unidadDeNegocio",Tools.getUTM('utm_unidad'));
    urlencoded.append("duplicado", 'Y');
    urlencoded.append("aplicacion", 'Visualizate Colegios');
    
    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow'
    };
    console.log('enla funcion', dataRegister)
    // fetch(dataRegister.URL_LEAD_REGISTER, requestOptions)
    // .then(response => response.json())
    // // .then(result => console.log(result))
    // .then(result => {
    //     //window.location.href = destino;
    //     console.log(destino);
    //     localStorage.setItem('campus_selected', Tools.cryptrData(dataRegister.campus));
    //     Tools.hidePreloader();
    //     return result;
    // })
    // .catch(error => error => console.log('error', error));

    const response = await fetch(dataRegister.URL_LEAD_REGISTER, requestOptions);
    const data = await response.json();
    return data;

}

class PageStepOne extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;

        this.state = {
            show: false,
            accept_politica: false,
            configuration: [],

            //DATOS PERSONALES:
            first_name: '',
            first_name_validate: false,
            last_name: '',
            last_name_validate: false,
            campus: '',
            phone: '',
            phone_validate: false,
            email: '',
            email_validate: false,
            nivel_estudios: '',
            nivel_estudios_validate: false,
            // assets view
            logo: '',
            image_view: '',

            // FORM REGISTER
            form_title_register: '',
            campus_list: [],
            politica_message: '',
            form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive',
            form_btn_2_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive',
            form_btn_3_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive',

            // GROUPS
            crm_data: [],
            distritos_remote: [],

            naturalezas: [],
            distritos: [],
            grupo_colegios: [],
            colegionaturaleza: null,
            select_distrito: null,
            select_grupo_colegio: null,
        }
    }

    componentDidMount() {
        Tools.showPreloader();
        document.title = "Visualizate | registro";

        let _root = this;
        let _campus_list = _root.QS.getCampusFromLocal();

        if(Tools.getUrlVars()['utm_colegioprincipal']) {
            _root.QS.getGroupColegios(Tools.getUrlVars()['utm_colegioprincipal'])
            .then(res => {
                let _drarr = [];
                let _nrarr = [];
                let naturalezas = [];

                for(let item of res) {
                    let _obj = {id_string: item.AccountDistId, label: `${item.AccountDepDesc} - ${item.AccountProvDesc} - ${item.AccountDistDesc}`, IdNaturaleza: item.IdNaturaleza};
                    _drarr.push(_obj);
                }

                for(let item of res) {
                    let _obj = {id: item.IdNaturaleza, label: item.DescNaturaleza};
                    _nrarr.push(_obj);
                }

                let map = _nrarr.reduce(function(prev, cur) {
                    prev[`${cur.id}`] = cur.label;
                    return prev;
                }, {});

                for (const prop in map) {
                    let _obj = {Id: parseInt(prop), Description: map[prop]};
                    naturalezas.push(_obj);
                }

                naturalezas.sort(function(a, b){
                    if(a.Description < b.Description) { return -1; }
                    if(a.Description > b.Description) { return 1; }
                    return 0;
                });
                
                _root.setState({
                    crm_data: res,
                    distritos_remote: _drarr,
                    naturalezas: naturalezas
                }, function() {
                    Tools.hidePreloader();
                });
            })
            .catch(err => {
                Tools.hidePreloader();
            });
        }
        else{
            Tools.hidePreloader();
        }

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ), Tools.getAllUrlVars() );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }
            _root.state.URL_LEAD_REGISTER = _root.state.configuration[0].field_api_lead_register[0].value;
            if( _root.state.configuration[0].field_imagenes_vistas.length > 0 ) {
                for( let item of _root.state.configuration[0].field_imagenes_vistas ) {
                    if( item.alt == 'view-register' ) {
                        _root.setState({
                            image_view: item.url
                        });
                    }
                }
            }

            if( _root.state.configuration[0].field_titulo_formulario_registro.length > 0 ) {
                _root.setState({
                    form_title_register: _root.state.configuration[0].field_titulo_formulario_registro[0].value
                });
            }

            if( _root.state.configuration[0].field_politica.length > 0 ) {
                _root.setState({
                    politica_message: _root.state.configuration[0].field_politica[0].value
                });
            }
            
            if( _campus_list.length > 0 ) {
                _root.setState({
                    campus_list: _campus_list
                });
            }
        });
    }

    handleShow = e => {
        e.preventDefault();
        this.setState({
            show: true
        });
    }

    handleClose = e => {
        this.setState({
            show: false
        });
    }

    handleAceptarPolitica = e => {
        e.preventDefault();
        let _root = this;
        document.getElementById('politica_privacidad').checked = true;
        this.setState({
            show: false,
            accept_politica: true
        }, function() {
            _root.checkFormIsComplete('3');
        });
    }

    _onChange = (e) => {
        if( e.target ) {
            let _root = this;
            let _target = e.target;
            if(_target.name != 'politica') {
                this.setState({
                    [_target.name]: _target.value
                }, function() {
                    if( _target.name == 'first_name' ) {
                        _root.checkFormIsComplete('1');
                    }
                    else if( _target.name == 'last_name' ) {
                        _root.checkFormIsComplete('1');
                    }
                    else if( _target.name == 'campus' ) {
                        _root.checkFormIsComplete('1');
                    }
                    else if (_target.name == 'nivel_estudios') {
                        _root.checkFormIsComplete('1');
                    }
                    else if( _target.name == 'phone' ) {
                        _root.checkFormIsComplete('3');
                    }
                    else if( _target.name == 'email' ) {
                        _root.checkFormIsComplete('3');
                    }
                    else if(_target.name == 'colegionaturaleza') {
                        let _drarr = [];
                        let distritos = [];
                        for(let item of _root.state.distritos_remote) {
                            if(item.IdNaturaleza == parseInt(_root.state.colegionaturaleza)) {
                                let _obj = {id: item.id_string, label: item.label};
                                _drarr.push(_obj);
                            }
                        }

                        let map = _drarr.reduce(function(prev, cur) {
                            prev[`${cur.id}`] = cur.label;
                            return prev;
                        }, {});

                        for (const prop in map) {
                            let _obj = {id: prop, label: map[prop]};
                            distritos.push(_obj);
                        }

                        distritos.sort(function(a, b){
                            if(a.label < b.label) { return -1; }
                            if(a.label > b.label) { return 1; }
                            return 0;
                        });
                        
                        _root.setState({
                            distritos: distritos,
                            grupo_colegios: [],
                            select_grupo_colegio: null,
                            select_distrito: null,
                            form_btn_2_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                        }, function() {
                            document.querySelector('.nav-tabs').childNodes[2].childNodes[0].classList.add('disabled');
                        });
                    }
                });
            }
            else {
                if(e.target.checked) {
                    this.setState({
                        accept_politica: true
                    }, function() {
                        _root.checkFormIsComplete('3');
                    });
                }
                else{
                    this.setState({
                        accept_politica: false
                    }, function() {
                        _root.checkFormIsComplete('3');
                    });
                }
            }
        }
    }

    checkFormIsComplete(form) {
        let _root = this;
        switch (form) {
            case '1':

                if( _root.state.first_name == '' ) {
                    _root.setState({
                        form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    }, function() {
                        document.querySelector('.nav-tabs').childNodes[1].childNodes[0].classList.add('disabled');
                    });
                }
                else if( _root.state.last_name == '' ) {
                    _root.setState({
                        form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    }, function() {
                        document.querySelector('.nav-tabs').childNodes[1].childNodes[0].classList.add('disabled');
                    });
                }
                else if( _root.state.campus == '' ) {
                    _root.setState({
                        form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    }, function() {
                        document.querySelector('.nav-tabs').childNodes[1].childNodes[0].classList.add('disabled');
                    });
                }
                else{
                    _root.setState({
                        form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 active'
                    }, function() {
                        document.querySelector('.nav-tabs').childNodes[1].childNodes[0].classList.remove('disabled');
                    });
                }

                break;

            case '2':

                _root.setState({
                    form_btn_2_class: 'btn btn-danger pt-3 pb-3 btn-style-3 active'
                }, function() {
                    document.querySelector('.nav-tabs').childNodes[2].childNodes[0].classList.remove('disabled');
                });

                break;

            case '3':
        
                if( _root.state.phone == '' ) {
                    _root.setState({
                        form_btn_3_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    });
                }
                else if( _root.state.email == '' ) {
                    _root.setState({
                        form_btn_3_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    });
                }
                else if( _root.state.accept_politica == false ) {
                    _root.setState({
                        form_btn_3_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
                    });
                }
                else{
                    _root.setState({
                        form_btn_3_class: 'btn btn-danger pt-3 pb-3 btn-style-3 active'
                    });
                }

                break;
        }
    }

    _onBlur = (e) => {
        let _target = e.target;
        let _root = this;

        if(_target.name == 'first_name') {
            if(_target.value.length > 0) {
                if(Tools.validateOnlyLetter(_target.value)) {
                    _root.setState({
                        first_name_validate: true
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '0';
                }
                else {
                    _root.setState({
                        first_name_validate: false
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '1';
                }
            }
            else {
                _root.setState({
                    first_name_validate: false
                });
                _target.parentNode.children[0].children[0].style.opacity = '0';
            }
        }

        if(_target.name == 'last_name') {
            if(_target.value.length > 0) {
                if(Tools.validateOnlyLetter(_target.value)) {
                    _root.setState({
                        last_name_validate: true
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '0';
                }
                else {
                    _root.setState({
                        last_name_validate: false
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '1';
                }
            }
            else {
                _root.setState({
                    last_name_validate: false
                });
                _target.parentNode.children[0].children[0].style.opacity = '0';
            }
        }

        if(_target.name == 'phone') {
            if(_target.value.length > 0) {
                if(Tools.validateCellPhoneNumber(_target.value)) {
                    _root.setState({
                        phone_validate: true
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '0';
                }
                else {
                    _root.setState({
                        phone_validate: false
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '1';
                }
            }
            else {
                _root.setState({
                    phone_validate: false
                });
                _target.parentNode.children[0].children[0].style.opacity = '0';
            }
        }

        if(_target.name == 'email') {
            if(_target.value.length > 0) {
                if(Tools.validateEmail(_target.value)) {
                    _root.setState({
                        email_validate: true
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '0';
                }
                else {
                    _root.setState({
                        email_validate: false
                    });
                    _target.parentNode.children[0].children[0].style.opacity = '1';
                }
            }
            else {
                _root.setState({
                    email_validate: false
                });
                _target.parentNode.children[0].children[0].style.opacity = '0';
            }
        }
    }

    onClickTab = e => {
        e.preventDefault();
        document.getElementById('complete-fields').style.display = 'none';
        let _current_tab = e.target.getAttribute('data-id');
        let _current_tab_index = ( e.target.getAttribute('id') ) ? e.target.getAttribute('id') : '';

        for(let item of document.querySelectorAll('.tabs-form .nav-tabs .nav-item a')) {
            item.classList.remove('active');
        }
        for(let item of document.querySelectorAll('.tabs-content')) {
            item.style.display = 'none';
        }
        
        if( _current_tab_index == '' ) {
            e.target.classList.add('active');
        }
        else {
            let _count = 0;
            for(let item of document.querySelectorAll('.tabs-form .nav-tabs .nav-item a')) {
                if(_count != parseInt(_current_tab_index)) {
                    item.classList.remove('active');
                }
                else {
                    item.classList.add('active');
                }
                _count++;
            }
        }
        document.getElementById( _current_tab ).style.display = 'block';
    }

    continue = e => {
        e.preventDefault();
        let _target = e.target;
        let _root = this;
        if( _root.state.first_name == '' ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else if( _root.state.last_name == '' ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else if( _root.state.campus == '' ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else if (_root.state.nivel_estudios == '') {
            _root.setState({
                form_btn_1_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive'
            });
        }
        else if( _root.state.phone == '' ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else if( _root.state.email == '' ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else if( _root.state.accept_politica == false ) {
            document.getElementById('complete-fields').style.display = 'block';
        }
        else{

            if(
                _root.state.first_name_validate &&
                _root.state.last_name_validate &&
                _root.state.phone_validate &&
                _root.state.email_validate
            ) {
                Tools.showPreloader();
                let data = [
                    {
                        first_name: _root.state.first_name,
                        last_name: _root.state.last_name,
                        campus: _root.state.campus,
                        phone: _root.state.phone,
                        email: _root.state.email
                    }
                ];
               
                leadRegitser(_root.state, _target.getAttribute('href'))
                .then(res2=>{
                    console.log(typeof( res2) );
                    let data_answer = [
                        {
                            title: '',
                            registrant_id: '',
                            question_group_list_id: '56',
                            get: {}
                        }
                    ];

                    if(typeof( res2) == 'number'){
                            let _un = `${data[0].first_name} ${data[0].last_name}`;
                            let _promotor_codcolegio = (_root.state.select_grupo_colegio.id).split('|');
                            if(Tools.getUrlVars()['utm_medium']) {
                                data_answer[0].get.MedioLanding = Tools.getUrlVars()['utm_medium'];
                            }
                            if(Tools.getUrlVars()['utm_campaign']) {
                                data_answer[0].get.UTM_3 = Tools.getUrlVars()['utm_campaign'];
                            }
                            if(Tools.getUrlVars()['utm_fuenteorigen']) {
                                data_answer[0].get.UTM_4 = Tools.getUrlVars()['utm_fuenteorigen'];
                            }
                            if(Tools.getUrlVars()['utm_detallefuente']) {
                                data_answer[0].get.UTM_5 = Tools.getUrlVars()['utm_detallefuente'];
                            }
                            if(Tools.getUrlVars()['utm_unidad']) {
                                data_answer[0].get.UTM_6 = Tools.getUrlVars()['utm_unidad'];
                            }
                            // if(Tools.getUrlVars()['utm_promotor']) {
                            //     data_answer[0].get.UTM_7 = Tools.getUrlVars()['utm_promotor'];
                            // }
                            data_answer[0].get.UTM_7 = _promotor_codcolegio[0];

                            if(Tools.getUrlVars()['utm_colegio']) {
                                data_answer[0].get.UTM_8 = Tools.getUrlVars()['utm_colegio'];
                            }

                            data_answer[0].get.IdCentroDocenteCRM = _promotor_codcolegio[1];
                            localStorage.setItem('campus_selected', Tools.cryptrData(data[0].campus));
                            localStorage.setItem('ur', Tools.cryptrData(res2));
                            localStorage.setItem('un', Tools.cryptrData(_un));
                            localStorage.setItem('data_answer', JSON.stringify(data_answer));

                            // console.log(data_answer)
                            // localStorage.setItem('campus_selected', Tools.cryptrData(data[0].campus));



                            document.getElementById('complete-fields').style.display = 'none';
                            console.log(data_answer);
                    }
                }).then(res3=>{
                    Tools.hidePreloader();
                    window.location.href = _target.getAttribute('href');
                })
                //_root.US.register(data, _root.QS.getTokenFromLocal())

                // .then(res2 => {

                //     console.log(res2.json())
                    
                //     let data_answer = [
                //         {
                //             title: '',
                //             registrant_id: '',
                //             question_group_list_id: '56',
                //             get: {}
                //         }
                //     ];

                //     if(res2.ids) {
                //         if(res2.ids.length > 0) {
                //             let _un = `${data[0].first_name} ${data[0].last_name}`;
                //             let _promotor_codcolegio = (_root.state.select_grupo_colegio.id).split('|');
                //             data_answer[0].title = `${res2.ids[0]}-56`;
                //             data_answer[0].registrant_id = res2.ids[0];
                            
                //             if(Tools.getUrlVars()['utm_medium']) {
                //                 data_answer[0].get.MedioLanding = Tools.getUrlVars()['utm_medium'];
                //             }
                //             if(Tools.getUrlVars()['utm_campaign']) {
                //                 data_answer[0].get.UTM_3 = Tools.getUrlVars()['utm_campaign'];
                //             }
                //             if(Tools.getUrlVars()['utm_fuenteorigen']) {
                //                 data_answer[0].get.UTM_4 = Tools.getUrlVars()['utm_fuenteorigen'];
                //             }
                //             if(Tools.getUrlVars()['utm_detallefuente']) {
                //                 data_answer[0].get.UTM_5 = Tools.getUrlVars()['utm_detallefuente'];
                //             }
                //             if(Tools.getUrlVars()['utm_unidad']) {
                //                 data_answer[0].get.UTM_6 = Tools.getUrlVars()['utm_unidad'];
                //             }
                //             // if(Tools.getUrlVars()['utm_promotor']) {
                //             //     data_answer[0].get.UTM_7 = Tools.getUrlVars()['utm_promotor'];
                //             // }
                //             data_answer[0].get.UTM_7 = _promotor_codcolegio[0];

                //             if(Tools.getUrlVars()['utm_colegio']) {
                //                 data_answer[0].get.UTM_8 = Tools.getUrlVars()['utm_colegio'];
                //             }

                //             data_answer[0].get.IdCentroDocenteCRM = _promotor_codcolegio[1];
                            
                //             localStorage.setItem('ur', Tools.cryptrData(res2.ids[0]));
                //             localStorage.setItem('un', Tools.cryptrData(_un));
                //             localStorage.setItem('campus_selected', Tools.cryptrData(data[0].campus));
                //             document.getElementById('complete-fields').style.display = 'none';
                //         }
                //     }
                    // return _root.QS.getAnswerId(data_answer, _root.QS.getTokenFromLocal());
                // })
                // .then(res3 => {
                //     Tools.hidePreloader();
                //     if(res3.idAnswers.length > 0) {
                //         localStorage.setItem('ia', Tools.cryptrData(res3.idAnswers[0]));
                //     }
                //     window.location.href = _target.getAttribute('href');
                // })
                // .catch(err => {
                //     error.json();
                // });
            }
            // else {
            //     Tools.showToastr('', 'Verifica y corrige los campos', 'error', 2000);
            // }
        }
    }

    handleChangeGroupColegio = (select_grupo_colegio) => {
        let _root = this;
        _root.setState({ 
            select_grupo_colegio: select_grupo_colegio
        }, function() {
            if(
                _root.state.colegionaturaleza !== null &&
                _root.state.select_distrito !== null &&
                _root.state.select_grupo_colegio !== null
            ) {
                _root.checkFormIsComplete('2');
            }
            else{
                document.querySelector('.nav-tabs').childNodes[2].childNodes[0].classList.add('disabled');
            }
        });
    }

    handleChangeDistrito = (select_distrito) => {
        let _root = this;
        _root.setState({
            form_btn_2_class: 'btn btn-danger pt-3 pb-3 btn-style-3 inactive',
            select_distrito: select_distrito,
            select_grupo_colegio: null,
            grupo_colegios: []
        }, function() {
            let colegios = [];
            for(let item of _root.state.crm_data) {
                if(item.AccountDistId == _root.state.select_distrito.id) {
                    let _obj = {id: `${item.CodPromotorCRM}|${item.IdAccountCRM}`, label: item.DescAccountCRM};
                    colegios.push(_obj);
                }
            }

            colegios.sort(function(a, b){
                if(a.label < b.label) { return -1; }
                if(a.label > b.label) { return 1; }
                return 0;
            });

            _root.setState({
                grupo_colegios: colegios
            }, function() {
                if(
                    _root.state.colegionaturaleza !== null &&
                    _root.state.select_distrito !== null &&
                    _root.state.select_grupo_colegio !== null
                ) {
                    _root.checkFormIsComplete('2');
                }
                else{
                    document.querySelector('.nav-tabs').childNodes[2].childNodes[0].classList.add('disabled');
                }
            });
        });
    }

    render() {
        const {logo, image_view, form_title_register, campus_list, show, accept_politica, politica_message, first_name, last_name, campus, phone, email, form_btn_1_class, form_btn_2_class, form_btn_3_class, grupo_colegios, naturalezas, distritos, select_distrito, select_grupo_colegio} = this.state;

        let _politica = '';
        _politica = politica_message.replace(/\xA0/g,' ').replace(/&nbsp;/gi," ");
        return (
            <div>
                <Header logo={logo}></Header>
                <div className="main-container init-form" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-7 image-view pl-5 pr-5">
                                <img className="img-fluid" src={image_view} />
                            </div>
                            <div className="col-sm-12 col-md-5 form-register">
                                <h1 className="title-style-2 mb-5">{form_title_register}</h1>

                                <div className="pl-3 pr-3">
                                    <div className="tabs-form">
                                        <ul className="nav nav-tabs">
                                            <li className="nav-item">
                                                <a className="nav-link active pt-3 pb-3 pl-2 pr-2" href="#" data-id="tab-1" onClick={this.onClickTab}>Queremos conocerte</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link disabled pt-3 pb-3 pl-2 pr-2" href="#" data-id="tab-2" onClick={this.onClickTab}>Tus estudios</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link disabled pt-3 pb-3 pl-2 pr-2" href="#" data-id="tab-3" onClick={this.onClickTab}>Déjanos tus datos</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div id="tab-1" className="tabs-content pl-4 pr-4 pt-3 pb-3">
                                        <div className="form-group">
                                            <label for="first_name">Nombres * <span className="badge badge-danger badge-error">Solo letras</span></label>
                                            <input type="text" className="form-control" id="first_name" name="first_name" placeholder="Ejemplo: Luis Carlos" defaultValue={first_name} onChange={this._onChange} onBlur={this._onBlur} />
                                        </div>
                                        <div className="form-group">
                                            <label for="last_name">Apellidos * <span className="badge badge-danger badge-error">Solo letras</span></label>
                                            <input type="text" className="form-control" id="last_name" name="last_name" placeholder="Ejemplo: Ruiz Hernández" defaultValue={last_name} onChange={this._onChange} onBlur={this._onBlur} />
                                        </div>
                                        <div className="form-group">
                                            <label for="campus">Campus *</label>
                                            <select className="form-control" id="campus" name="campus" onChange={this._onChange}>
                                                <option selected disabled>Elige un campus de la lista</option>
                                                {
                                                    (campus_list.length > 0)
                                                    ?
                                                        campus_list.map((item, i) => (
                                                            <option key={i} value={item.code}>{item.title}</option>
                                                        ))
                                                    :
                                                        ''
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="nivel_estudios">Nivel de estudios*</label>
                                            <select className='form-control' name="nivel_estudios" id="nivel_estudios" onChange={this._onChange}>
                                                <option selected disabled>Elige tu nivel de la lista</option>
                                                <option value="2">Culminé el Colegio</option>
                                                <option value="6">5to de secundaria</option>
                                                <option value="5">4to de secundaria</option>
                                                <option value="4">3ro de secundaria</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <small>(*) campos obligatorios</small>
                                        </div>

                                        <div className="btn-group mt-3" role="group">
                                            <a href="#" id="form-btn-1" className={form_btn_1_class} data-id="tab-2" id="1" onClick={this.onClickTab} style={{marginLeft: 'calc(50% + .5rem)'}}>Siguiente</a>
                                        </div>
                                    </div>
                                    <div id="tab-2" className="tabs-content pl-4 pr-4 pt-3 pb-3">
                                        <div className="form-group">
                                            <label for="campus">Tu colegio es *</label>
                                            <select className="form-control" id="colegionaturaleza" name="colegionaturaleza" onChange={this._onChange}>
                                                <option selected disabled>Selecciona</option>
                                                {
                                                    (naturalezas.length > 0)
                                                    ?
                                                        naturalezas.map((item, i) => (
                                                            <option key={i} value={item.Id}>{item.Description}</option>
                                                        ))
                                                    :
                                                        ''
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group school-group">
                                            <label>En que distrito estudias *</label>
                                            <Select
                                                value={select_distrito}
                                                onChange={this.handleChangeDistrito}
                                                options={distritos}
                                                placeholder="Selecciona"
                                            />
                                        </div>
                                        <div className="form-group school-group">
                                            <label>Nombre de tu colegio *</label>
                                            <Select
                                                value={select_grupo_colegio}
                                                onChange={this.handleChangeGroupColegio}
                                                options={grupo_colegios}
                                                placeholder="Selecciona"
                                            />
                                        </div>
                                        <div className="form-group">
                                            <small>(*) campos obligatorios</small>
                                        </div>
                                        <div className="btn-group mt-3" role="group">
                                            <a href="#" className="btn btn-secondary mr-2 pt-3 pb-3 btn-style-3" data-id="tab-1" id="0" onClick={this.onClickTab}>Anterior</a>
                                            <a href="#" id="form-btn-2" className={form_btn_2_class} data-id="tab-3" id="2" onClick={this.onClickTab}>Siguiente</a>
                                        </div>
                                    </div>
                                    <div id="tab-3" className="tabs-content pl-4 pr-4 pt-3 pb-3">
                                        <div className="form-group">
                                            <label for="phone">Celular * <span className="badge badge-danger badge-error">Solo números y el 9 adelante</span></label>
                                            <input type="text" className="form-control" id="phone" name="phone" maxLength="9" placeholder="Ejemplo: 987654321" defaultValue={phone} onChange={this._onChange} onBlur={this._onBlur} />
                                        </div>
                                        <div className="form-group">
                                            <label for="email">Email * <span className="badge badge-danger badge-error">Correo no válido</span></label>
                                            <input type="text" className="form-control" id="email" name="email" placeholder="Ejemplo: micorreo@gmail.com" defaultValue={email} onChange={this._onChange} onBlur={this._onBlur} autoComplete="off" />
                                        </div>
                                        <div className="form-group form-check">
                                            <input type="checkbox" className="form-check-input" name="politica" onChange={this._onChange} id="politica_privacidad"/>
                                            <label className="form-check-label" for="politica_privacidad">Acepto las <a href="#" onClick={this.handleShow}>políticas de privacidad</a></label>
                                        </div>
                                        <div className="form-group">
                                            <small>(*) campos obligatorios</small>
                                        </div>
                                        <div className="form-group" id="complete-fields">
                                            <span className="badge badge-warning">Necesitas completar todos los campos</span>
                                        </div>

                                        <div className="btn-group mt-3" role="group">
                                            <a href="#" className="btn btn-secondary mr-2 pt-3 pb-3 btn-style-3" data-id="tab-2" id="1" onClick={this.onClickTab}>Anterior</a>
                                            <a href={URLPREFIX + '/step-two'} id="form-btn-2" className={form_btn_3_class} onClick={this.continue}>¡Empecemos!</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal show={show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    <Modal.Title>Políticas de privacidad</Modal.Title>
                    </Modal.Header>
                    <Modal.Body dangerouslySetInnerHTML={{__html: _politica}}></Modal.Body>
                    <Modal.Footer>
                    <Button className="btn ok-btn btn-secondary btn-style-2" onClick={this.handleClose}>
                        Cancelar
                    </Button>
                    <Button className="btn ok-btn btn-primary btn-style-2" onClick={this.handleAceptarPolitica}>
                        Aceptar
                    </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}

export default PageStepOne;