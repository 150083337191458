import dotenv from "dotenv";
dotenv.config();

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { ReCaptcha, loadReCaptcha } from 'react-recaptcha-v3'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { Provider } from "react-redux";
import styles from './styles'
import Routes from './routes';
import store from "./store";

const verifyCallback = token => {
    // Here you will get the final token!!!
    console.log(token, 'recaptcha active');
}

class App extends Component {
    componentDidMount() {
        console.log("%c 1.2.3 %cUTP-JAS","padding: 5px; border-radius: 4px 0 0 4px; background: linear-gradient(#ec5370, #d3052d); color: #FFF; text-transform: uppercase; font-size: 10px;font-family: sans-serif;font-weight: bold;text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.3)","padding: 5px; border-radius: 0 4px 4px 0; background-color: #fe861e; color: #FFF; text-transform: uppercase; font-size: 10px;font-family: sans-serif");
    }
    render() {
        return (
            <>
            <ReCaptcha
                action='main'
                sitekey='6LeV2K4ZAAAAAK6Po6T7A1to8cH9GQ9mt4HMiiDM'
                verifyCallback={verifyCallback}
                />

            <Provider store={store()}>
                <Router>
                    <Routes />
                </Router>
            </Provider>
            </>
        )
    }
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);
