import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import Student01 from '../../../assets/images/student-01.png';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepTwentySix extends Component {

    componentDidMount() {
        document.title = "StepTwentySix";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 col-md-6">
                                <h1 className="title-style-4 pl-5 pr-5">Cuando estoy triste, sé la razón del porqué</h1>
                            </div>
                        </div>

                        <div className="row justify-content-center mt-5">
                            <div className="col-sm-12 col-md-4">
                                <div className="btn-group pl-3 pr-3 style-1" role="group">
                                    <a href="/step-twentyseven" className="btn btn-danger mr-2 pt-3 pb-3 btn-style-2">Si</a>
                                    <a href="/step-twentyseven" className="btn btn-danger ml-3 pt-3 pb-3 btn-style-2">No</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepTwentySix;