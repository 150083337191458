import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import ObjetoEncaja1 from '../../../assets/images/objeto-encaja/objeto-encaja-1.svg';
import ObjetoEncaja2 from '../../../assets/images/objeto-encaja/objeto-encaja-2.svg';
import ObjetoEncaja3 from '../../../assets/images/objeto-encaja/objeto-encaja-3.svg';
import ObjetoEncaja4 from '../../../assets/images/objeto-encaja/objeto-encaja-4.svg';
import ObjetoEncaja5 from '../../../assets/images/objeto-encaja/objeto-encaja-5.svg';
import ObjetoEncaja6 from '../../../assets/images/objeto-encaja/objeto-encaja-6.svg';
import ObjetoEncaja7 from '../../../assets/images/objeto-encaja/objeto-encaja-7.svg';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepThirty extends Component {

    componentDidMount() {
        document.title = "StepThirty";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <h1 className="title-style-4 pl-5 pr-5">¿Cuál es el objeto que encaja en esta figura?</h1>
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-2">
                                    <div className="col-sm-12 col-md-8" style={{textAlign: 'center'}}>
                                        <img src={ObjetoEncaja1} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-sm-8">
                                        <div className="btn-group style-1" role="group">
                                            <div className="container">
                                                <div className="row align-items-end pt-5">
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja2} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">1</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja3} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">2</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja4} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">3</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja5} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">4</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja6} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">5</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-4 mb-5 objecto">
                                                        <img className="mb-4" src={ObjetoEncaja7} />
                                                        <a href="/step-thirtyone" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">6</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepThirty;