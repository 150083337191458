import React, { Component } from 'react';
import { Tools } from '../../tools';
import Question from '../../models/questions';
import User from '../../models/user';

import BgMain from '../../../assets/images/bg-main-2.png';

import QuestionType from '../../components/QuestionType';

class PageStepTwenty extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;
        this.continue = this.continue.bind(this);

        this.state = {
            other_profiles: [],
            configuration: [],
            question: {},

            // assets view
            logo: ''
        }
    }

    componentDidMount() {
        document.title = "StepTwenty";

        let _root = this;

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ) );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }
        });

        if( localStorage.getItem('other_profiles') ) {
            if( localStorage.getItem('other_profiles') != '' ) {
                _root.setState({
                    other_profiles: JSON.parse(Tools.decryptrData(localStorage.getItem('other_profiles')))
                }, function() {
                    if( _root.state.other_profiles.length > 9 ) {
                        _root.setState({
                            question: _root.state.other_profiles[9]
                        });
                    }
                });
            }
        }
    }

    continue(e) {
        e.preventDefault();

        let _root = this;
        let _target = e.target;

        let _nmore = true;
        for(let si of JSON.parse(Tools.decryptrData(localStorage.getItem('desktop_selected_items')))) {
            if(si.selected && (si.selected == true)) {
                _nmore = true;
            }
            else {
                _nmore = false;
            }
        }

        if(_nmore) {
            window.location.href = URLPREFIX + '/step-thirtythree';
        }
        else {
            window.location.href = URLPREFIX + '/step-nine';
        }

    }

    render() {
        const {logo, question} = this.state;

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href={URLPREFIX + '/'}>
                                <img className="logo" src={logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    {
                        (question.question_type)
                        ?
                        <QuestionType type={question.question_type.id} question={question} next_path={URLPREFIX + '/step-twentyone'} from={'psi'} />
                        :
                        ''
                    }
                </div>
            </div>
        )
    }
}

export default PageStepTwenty;