import axios from "axios";
import { APIS } from "../config_apis";
import { Tools } from "../tools";


class Question {

    constructor() {
    }

    // GET TOKEN SESSION FOR REST API
    getToken() {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.token}`,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    // GET GLOBAL CONFIGURATION
    getConfiguration() {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.configuration}`,
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    // PROFILES LIST QUESTIONS
    getQuestionGroupList(group_id, token) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.question_group_list}${group_id}?_format=hal_json`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    getQuestionGroup(group_id, token) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.question_group}${group_id}?_format=hal_json`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    getAnswerId(data, token) {
        let response = '';
        return axios({
            method: 'POST',
            url: `${APIS.answer}`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            },
            data: data
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    sendResult(data, token) {
        let response = '';
        return axios({
            method: 'POST',
            url: `${APIS.result}${Tools.decryptrData(localStorage.getItem('ia'))}/result?_format=hal_json`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            },
            data: data
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    sendQuestionResult(data, token) {
        let response = '';
        return axios({
            method: 'POST',
            url: `${APIS.question_answers}${Tools.decryptrData(localStorage.getItem('ia'))}/question?_format=hal_json`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            },
            data: data
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    // GET CAMPUS LIST API
    getCampusList(token) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.campus_list}`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    // GET CAMPUS BY ID
    getCampusById(token, id) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.campus_byid}${id}?_format=hal_json`,
            headers: {
                'Content-Type': 'application/hal+json',
                'X-CSRF-Token': token,
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    // realista (12)
    // investigador (13)
    // artista (14)
    // social (15)
    // emprendedor (16)
    // convencional (17)
    buidlProfileQuestions(res) {
        let response = [];

        if( res.questions.length > 0 ) {
            let p1 = [];
            let p2 = [];
            let p3 = [];
            let p4 = [];
            let p5 = [];
            let p6 = [];
            let per1 = {id: '', title: '', questions: []};
            let per2 = {id: '', title: '', questions: []};
            let per3 = {id: '', title: '', questions: []};
            let per4 = {id: '', title: '', questions: []};
            let per5 = {id: '', title: '', questions: []};
            let per6 = {id: '', title: '', questions: []};

            for(let item of res.questions) {
                switch (item.profile.id) {
                    case '12':
                        p1.push(item);
                        per1.id = item.profile.id;
                        per1.title = item.profile.title;
                        per1.questions = p1;
                        break;
                    case '13':
                        p2.push(item);
                        per2.id = item.profile.id;
                        per2.title = item.profile.title;
                        per2.questions = p2;
                        break;
                    case '14':
                        p3.push(item);
                        per3.id = item.profile.id;
                        per3.title = item.profile.title;
                        per3.questions = p3;
                        break;
                    case '15':
                        p4.push(item);
                        per4.id = item.profile.id;
                        per4.title = item.profile.title;
                        per4.questions = p4;
                        break;
                    case '16':
                        p5.push(item);
                        per5.id = item.profile.id;
                        per5.title = item.profile.title;
                        per5.questions = p5;
                        break;
                    case '17':
                        p6.push(item);
                        per6.id = item.profile.id;
                        per6.title = item.profile.title;
                        per6.questions = p6;
                        break;
                }
            }

            if( per1.questions.length > 0) {
                response.push(per1);
            }
            if( per2.questions.length > 0) {
                response.push(per2);
            }
            if( per3.questions.length > 0) {
                response.push(per3);
            }
            if( per4.questions.length > 0) {
                response.push(per4);
            }
            if( per5.questions.length > 0) {
                response.push(per5);
            }
            if( per6.questions.length > 0) {
                response.push(per6);
            }
        }

        return response;
    }

    // RANDOM SELECT QUESTION FROM ARRAY
    getQuestionRandom(questions) {
        let _question_num_random = Math.floor(Math.random() * questions.length);

        return questions[_question_num_random];
    }

    // SET AND GET TOKEN FOR REST - LOCAL STORAGE
    setTokenToLocal(data) {
        localStorage.setItem('rest_token', Tools.cryptrData( data ));
    }
    getTokenFromLocal() {
        return Tools.decryptrData(localStorage.getItem('rest_token'));
    }

    // SET AND GET CAMPUS - LOCAL STORAGE
    setCampusToLocal(data) {
        localStorage.setItem('campus', Tools.cryptrData( JSON.stringify(data) ));
    }
    getCampusFromLocal() {
        return JSON.parse( Tools.decryptrData(localStorage.getItem('campus')) );
    }

    // SET AND GET PROFILES - LOCAL STORAGE
    setProfilesToLocal(data) {
        localStorage.setItem('profiles', Tools.cryptrData( JSON.stringify(data) ));
    }
    getProfilesFromLocal() {
        return JSON.parse( Tools.decryptrData(localStorage.getItem('profiles')) );
    }

    // SET AND GET CONFIGURATION - LOCAL STORAGE
    setConfigurationToLocal(data) {
        localStorage.setItem('configuration', Tools.cryptrData( JSON.stringify(data) ));
    }
    getConfigurationFromLocal() {
        return JSON.parse( Tools.decryptrData(localStorage.getItem('configuration')) );
    }

    // SAVE OPTIONS ANSWER PROFILES QUESTIONS
    saveOptionAnswerQuestionProfile(item) {
        if(localStorage.getItem('profiles_answer')) {
            let _profiles_answer = JSON.parse( Tools.decryptrData(localStorage.getItem('profiles_answer')) );
            _profiles_answer.push(item);
            localStorage.setItem('profiles_answer', Tools.cryptrData( JSON.stringify(_profiles_answer) ));
        }
        else {
            let _profiles_answer = [];
            _profiles_answer.push(item);
            localStorage.setItem('profiles_answer', Tools.cryptrData( JSON.stringify(_profiles_answer) ));
        }
    }

    getOptionsAnswerQuestionProfile() {
        return JSON.parse( Tools.decryptrData( localStorage.getItem('profiles_answer') ) );
    }

    popOptionAnswerQuestionProfile() {
        if(localStorage.getItem('profiles_answer')) {
            let _profiles_answer = JSON.parse( Tools.decryptrData(localStorage.getItem('profiles_answer')) );
            _profiles_answer.pop();
            localStorage.setItem('profiles_answer', Tools.cryptrData( JSON.stringify(_profiles_answer) ));
        }
        else {
            let _profiles_answer = [];
            _profiles_answer.pop();
            localStorage.setItem('profiles_answer', Tools.cryptrData( JSON.stringify(_profiles_answer) ));
        }
    }

    checkOptionAnswerQuestionProfile(profiles_answer, desktops) {
        let data = [];
        let _desktops = JSON.parse(Tools.decryptrData(localStorage.getItem('desktops')));
        let answers = [];
        for(let item of profiles_answer) {
            if(item.is_option_correct == '1') {
                for(let d of _desktops) {
                    if( item.profile.id == d.profile.id ) {
                        let _urlexternal = Tools.extractProtocol(d.images[0].src) + '://' + Tools.extractHostname(d.images[0].src);
                        d.images[0].src = (d.images[0].src).replace(_urlexternal, URL);
                        if( Tools.extractProtocol(d.images[0].src) != 'https' ){d.images[0].src.replace('http', 'https');}
                        data.push(d);
                    }
                }
                answers.push(item);
            }
        }

        return data;

        // return this.getRandomElementsFromArray(desktops, answers.length);
    }

    getRandomElementsFromArray(arr, n) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError('getRandom: more elements taken than available');
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    getGroupColegios(id) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.grupo_colegios}${id}`,
            headers: {
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    getNaturalezas() {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.naturalezas}`,
            headers: {
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

    getDistrito(filter) {
        let response = '';
        return axios({
            method: 'GET',
            url: `${APIS.distritos}${filter}`,
            headers: {
                'Authorization': APIS.basic_auth
            }
        })
        .then(res => {
            if(res.data) {
                response = res.data;
                return response;
            }
            else {
                return response;
            }
        })
        .catch(err => {
            return err;
        });
    }

}

export default Question;