import React, { Component } from 'react';
import { Tools } from '../../tools';
import Question from '../../models/questions';
import User from '../../models/user';

// COMPONENTS
import Header from '../../components/Header';
import QuestionType from '../../components/QuestionType';

import BgMain from '../../../assets/images/bg-main-2.png';

class PageStepSix extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;
        this.continue = this.continue.bind(this);

        this.state = {
            profiles: [],
            configuration: [],
            question: {},

            // assets view
            logo: ''
        }
    }

    componentDidMount() {
        this.US.checkUserId();
        document.title = "Visualizate | paso 5";

        let _root = this;

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ) );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }
        });

        if( localStorage.getItem('profiles') ) {
            if( localStorage.getItem('profiles') != '' ) {
                _root.setState({
                    profiles: _root.QS.getProfilesFromLocal()
                }, function() {
                    for(let item of _root.state.profiles) {
                        if( item.id == '16' ) {
                            _root.setState({
                                question: _root.QS.getQuestionRandom(item.questions)
                            }, function() {
                                if( item.questions.length > 1 ) {
                                    for(let i = 0; i < item.questions.length; i++) {
                                        if( item.questions[i].id == _root.state.question.id) {
                                            item.questions.splice(i, 1);
                                        }
                                    }
                                    _root.QS.setProfilesToLocal(_root.state.profiles);
                                }
                            });
                            break;
                        }
                    }
                });
            }
        }
    }

    continue(item, e) {
        e.preventDefault();

        let _root = this;
        _root.QS.saveOptionAnswerQuestionProfile(item);

        window.location.href = e.target.getAttribute('href');
    }

    render() {
        const {logo, question} = this.state;

        return (
            <div>
                <Header logo={logo}></Header>
                <div className="main-container center" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    {
                        (question.question_type)
                        ?
                        <QuestionType type={question.question_type.id} question={question} next_path={URLPREFIX + '/step-seven'} />
                        :
                        ''
                    }
                </div>
            </div>
        )
    }
}

export default PageStepSix;