import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import CheckOffIcon from '../../../assets/icons/check-off-icon.svg';
import CheckOnIcon from '../../../assets/icons/check-on-icon.svg';

import NumeroOrden1 from '../../../assets/images/ordena-numeros/ordena-1.svg';
import NumeroOrden2 from '../../../assets/images/ordena-numeros/ordena-2.svg';
import NumeroOrden3 from '../../../assets/images/ordena-numeros/ordena-3.svg';
import NumeroOrden4 from '../../../assets/images/ordena-numeros/ordena-4.svg';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepTwentyFour extends Component {

    componentDidMount() {
        document.title = "StepTwentyFour";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="q-panel">
                        <div className="container style-1">
                            <div className="col-sm-12 col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <h1 className="title-style-4 text-show-responsive pl-5 pr-5">Ordena de menor a mayor los números mostrados por las niñas y los niños</h1>
                                    </div>
                                    <div className="col-sm-12 number-order image-responsive">
                                        <img src={'http://back-visualizate.drinux.com/sites/default/files/questions/imagen01.png'} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <h1 className="title-style-4 text-hide-responsive pl-5 pr-5">Ordena de menor a mayor los números mostrados por las niñas y los niños</h1>
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-2">
                                    <div className="col-sm-12 col-md-8">
                                        <div className="btn-group pl-3 pr-3 style-2" role="group">
                                            <a href="/step-twentyfive" className="btn mb-4 pl-5 pt-3 pb-3 btn-style-4">
                                                <span className="check-off"><img src={CheckOffIcon} /></span>
                                                <span className="check-on"><img src={CheckOnIcon} /></span>
                                                83 - 43 - 53 - 63</a>
                                            <a href="/step-twentyfive" className="btn mb-4 pl-5 pt-3 pb-3 btn-style-4">
                                                <span className="check-off"><img src={CheckOffIcon} /></span>
                                                <span className="check-on"><img src={CheckOnIcon} /></span>
                                                43 - 53 - 63 - 83</a>
                                            <a href="/step-twentyfive" className="btn pl-5 mb-4 pt-3 pb-3 btn-style-4">
                                                <span className="check-off"><img src={CheckOffIcon} /></span>
                                                <span className="check-on"><img src={CheckOnIcon} /></span>
                                                63 - 43 - 83 - 53</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepTwentyFour;