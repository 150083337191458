/*
    This is here where all the routes lives
 */

import React, { Component } from 'react'
import { Route } from 'react-router-dom'
import preloader_image from '../assets/images/ball-triangle.svg';

// Layout
import PageLayout from './layouts/PageLayout';

// Pages
import PageHome from './pages/Home';
import PageStepOne from './pages/StepOne';
import PageStepTwo from './pages/StepTwo';
import PageStepThree from './pages/StepThree';
import PageStepFour from './pages/StepFour';
// import PageTest from './pages/Test';
import PageStepFive from './pages/StepFive';
import PageStepSix from './pages/StepSix';
import PageStepSeven from './pages/StepSeven';
import PageStepEight from './pages/StepEight';
import PageStepNine from './pages/StepNine';
import PageStepTen from './pages/StepTen';
import PageStepEleven from './pages/StepEleven';
import PageStepTwelve from './pages/StepTwelve';
import PageStepThirteen from './pages/StepThirteen';
import PageStepFourteen from './pages/StepFourteen';
import PageStepFifteen from './pages/StepFifiteen';
import PageStepSixteen from './pages/StepSixteen';
import PageStepSeventeen from './pages/StepSeventeen';
import PageStepEightteen from './pages/StepEightteen';
import PageStepNineteen from './pages/StepNineteen';
import PageStepTwenty from './pages/StepTwenty';
import PageStepTwentyOne from './pages/StepTwentyOne';
import PageStepTwentyTwo from './pages/StepTwentyTwo';
import PageStepTwentyThree from './pages/StepTwentyThree';
import PageStepTwentyFour from './pages/StepTwentyFour';
import PageStepTwentyFive from './pages/StepTwentyFive';
import PageStepTwentySix from './pages/StepTwentySix';
import PageStepTwentySeven from './pages/StepTwentySeven';
import PageStepTwentyEight from './pages/StepTwentyEight';
import PageStepTwentyNine from './pages/StepTwentyNine';
import PageStepThirty from './pages/StepThirty';
import PageStepThirtyOne from './pages/StepThirtyOne';
import PageStepThirtyTwo from './pages/StepThirtyTwo';
import PageStepThirtyThree from './pages/StepThirtyThree';
import PageStepThirtyFour from './pages/StepThirtyFour';


class Routes extends Component {
    render() {
        return (
            <div>
                <Route exact={true} path={URLPREFIX + '/'} component={PageHome} />
                <Route exact={true} path={URLPREFIX + '/step-one'} component={PageStepOne} />
                <Route exact={true} path={URLPREFIX + '/step-two'} component={PageStepTwo} />
                <Route exact={true} path={URLPREFIX + '/step-three'} component={PageStepThree} />
                <Route exact={true} path={URLPREFIX + '/step-four'} component={PageStepFour} />
                <Route exact={true} path={URLPREFIX + '/step-five'} component={PageStepFive} />
                <Route exact={true} path={URLPREFIX + '/step-six'} component={PageStepSix} />
                <Route exact={true} path={URLPREFIX + '/step-seven'} component={PageStepSeven} />
                <Route exact={true} path={URLPREFIX + '/step-eight'} component={PageStepEight} />
                <Route exact={true} path={URLPREFIX + '/step-nine'} component={PageStepNine} />
                <Route exact={true} path={URLPREFIX + '/step-ten'} component={PageStepTen} />
                <Route exact={true} path={URLPREFIX + '/step-eleven'} component={PageStepEleven} />
                <Route exact={true} path={URLPREFIX + '/step-twelve'} component={PageStepTwelve} />
                <Route exact={true} path={URLPREFIX + '/step-thirteen'} component={PageStepThirteen} />
                <Route exact={true} path={URLPREFIX + '/step-fourteen'} component={PageStepFourteen} />
                <Route exact={true} path={URLPREFIX + '/step-fifteen'} component={PageStepFifteen} />
                <Route exact={true} path={URLPREFIX + '/step-sixteen'} component={PageStepSixteen} />
                <Route exact={true} path={URLPREFIX + '/step-seventeen'} component={PageStepSeventeen} />
                <Route exact={true} path={URLPREFIX + '/step-eightteen'} component={PageStepEightteen} />
                <Route exact={true} path={URLPREFIX + '/step-nineteen'} component={PageStepNineteen} />
                <Route exact={true} path={URLPREFIX + '/step-twenty'} component={PageStepTwenty} />
                <Route exact={true} path={URLPREFIX + '/step-twentyone'} component={PageStepTwentyOne} />
                <Route exact={true} path={URLPREFIX + '/step-twentytwo'} component={PageStepTwentyTwo} />
                <Route exact={true} path={URLPREFIX + '/step-twentythree'} component={PageStepTwentyThree} />
                <Route exact={true} path={URLPREFIX + '/step-twentyfour'} component={PageStepTwentyFour} />
                <Route exact={true} path={URLPREFIX + '/step-twentyfive'} component={PageStepTwentyFive} />
                <Route exact={true} path={URLPREFIX + '/step-twentysix'} component={PageStepTwentySix} />
                <Route exact={true} path={URLPREFIX + '/step-twentyseven'} component={PageStepTwentySeven} />
                <Route exact={true} path={URLPREFIX + '/step-twentyeight'} component={PageStepTwentyEight} />
                <Route exact={true} path={URLPREFIX + '/step-twentynine'} component={PageStepTwentyNine} />
                <Route exact={true} path={URLPREFIX + '/step-thirty'} component={PageStepThirty} />
                <Route exact={true} path={URLPREFIX + '/step-thirtyone'} component={PageStepThirtyOne} />
                <Route exact={true} path={URLPREFIX + '/step-thirtytwo'} component={PageStepThirtyTwo} />
                <Route exact={true} path={URLPREFIX + '/step-thirtythree'} component={PageStepThirtyThree} />
                <Route exact={true} path={URLPREFIX + '/step-thirtyfour'} component={PageStepThirtyFour} />


                {/* <Route exact={true} path="/test" component={PageTest} /> */}

                <div id="preloader">
                    <img src={URLPREFIX + preloader_image} />
                </div>
            </div>
        )
    }
}

export default Routes;