import React, { Component } from 'react';
import { Markup } from 'interweave';
import Question from '../../models/questions';
import User from '../../models/user';
import { Tools } from '../../tools';

// COMPONENTS
import Header from '../../components/Header';

import BgMain from '../../../assets/images/bg-main-2.png';

import DesktopImageSelect from '../../../assets/images/desktop-image-select.png';

class PageStepEight extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;

        this.continue = this.continue.bind(this);
        this.onClickDeskTopImage = this.onClickDeskTopImage.bind(this);

        this.state = {
            desktop_img_selected: 0,
            profiles: [],
            answer_profiles: [],
            configuration: [],
            question: {},

            // assets view
            logo: '',
            mensaje_escritorio: '',
            images_escritorios: []
        }
    }

    componentDidMount() {
        this.US.checkUserId();
        localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify([]) ));
        localStorage.setItem('aps', Tools.cryptrData(''));
        document.title = "Visualizate | selección de escritorio";

        let _root = this;

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ) );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }

            let _desktops = JSON.parse(Tools.decryptrData(localStorage.getItem('desktops')));

            if( _desktops.length > 0 ) {
                _root.setState({
                    answer_profiles: _root.QS.getOptionsAnswerQuestionProfile()
                }, function() {
                    let _profilestosend = [];
                    for(let p of _root.state.answer_profiles) {
                        if(p.is_option_correct == '1') {
                            _profilestosend.push(p.profile);
                        }
                    }
                    localStorage.setItem('aps', Tools.cryptrData(JSON.stringify(_profilestosend)));

                    let _desktops_result = _root.QS.checkOptionAnswerQuestionProfile(_root.state.answer_profiles, []);
                    if( _desktops_result.length > 0 ) {
                        _root.setState({
                            images_escritorios: _desktops_result
                        }, function() {
                            if( _root.state.images_escritorios.length == 1 ) {
                                if(_root.state.configuration[0].field_mensaje_escritorio_1.length > 0) {
                                    document.getElementById('desktop-title').innerHTML = _root.state.configuration[0].field_mensaje_escritorio_1[0].value;
                                }
                            }
                            else if( _root.state.images_escritorios.length > 1) {
                                if(_root.state.configuration[0].field_mensaje_escritorio_2.length > 0) {
                                    document.getElementById('desktop-title').innerHTML = _root.state.configuration[0].field_mensaje_escritorio_2[0].value;
                                }
                            }
                        });
                    }
                    else {
                        window.location.href = URLPREFIX + '/step-two';
                    }
                });
            }
        });
    }

    onClickDeskTopImage(item, e) {
        e.preventDefault();

        let _root = this;
        let count_selected = 2;

        if( _root.state.images_escritorios.length == 1 ) {
            count_selected = 1;
        }
        else if( _root.state.images_escritorios.length > 1) {
            count_selected = 2;
        }

        // if( _root.state.desktop_img_selected < count_selected ) {
            if( e.currentTarget ) {
                if( !e.currentTarget.classList.contains('active') ) {
                    if(_root.state.desktop_img_selected < count_selected) {
                        e.currentTarget.classList.add('active');
                        _root.setState({
                            desktop_img_selected: (_root.state.desktop_img_selected + 1)
                        }, function() {
                            if( localStorage.getItem('desktop_selected_items') ) {
                                let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items')) );
                                _dsi.push(item);
                                localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify(_dsi) ));
                            }

                            if(_root.state.desktop_img_selected == count_selected) {
                                document.getElementById('next-btn').classList.remove('inactive');
                                document.getElementById('next-btn').classList.add('active');
                            }
                        });
                    }
                }
                else {
                    document.getElementById('next-btn').classList.remove('active');
                    document.getElementById('next-btn').classList.add('inactive');

                    e.currentTarget.classList.remove('active');
                    _root.setState({
                        desktop_img_selected: (_root.state.desktop_img_selected - 1)
                    }, function() {
                        if( localStorage.getItem('desktop_selected_items') ) {
                            let _dsi = JSON.parse( Tools.decryptrData(localStorage.getItem('desktop_selected_items')) );
                            _dsi = _dsi.filter(function(it) {
                                return it.id !== item.id
                            });
                            localStorage.setItem('desktop_selected_items', Tools.cryptrData( JSON.stringify(_dsi) ));
                        }
                    });
                }
            }
        
    }

    continue(e) {
        e.preventDefault();

        let _root = this;
        let count_selected = 2;

        if( _root.state.images_escritorios.length == 1 ) {
            count_selected = 1;
        }
        else if( _root.state.images_escritorios.length > 1) {
            count_selected = 2;
        }

        if(_root.state.desktop_img_selected == count_selected) {
            window.location.href = e.target.getAttribute('href');
        }
    }

    render() {
        const {logo, images_escritorios, mensaje_escritorio} = this.state;

        return (
            <div>
                <Header logo={logo}></Header>
                <div className="main-container center" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div id="desktop-title" className="col-sm-12 col-md-6">
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="row justify-content-center">
                                    {
                                        (images_escritorios.length > 0)
                                        ?
                                            images_escritorios.map((item, i) => (
                                            <div  key={i} className="col-sm-6 pt-3 pb-3 desktop-image" onClick={this.onClickDeskTopImage.bind(this, item)}>
                                                <img src={item.images[0].src} className="img-fluid" />
                                                <img src={URLPREFIX + DesktopImageSelect} className="img-fluid select" />
                                            </div>
                                            ))
                                        :
                                            ''
                                    }

                                    {
                                        (images_escritorios.length == 1)
                                        ?
                                        <div className="col-sm-12 pt-3 pb-3 btn-container-1 center-btn">
                                            <a id="next-btn" href={URLPREFIX + '/step-nine'} className="btn btn-danger pt-3 pb-3 btn-style-3 inactive" style={{float: 'right', width: '50%'}} onClick={this.continue.bind(this)}>Siguiente</a>
                                        </div>
                                        :
                                        <div className="col-sm-12 pt-3 pb-3 btn-container-1">
                                            <a id="next-btn" href={URLPREFIX + '/step-nine'} className="btn btn-danger pt-3 pb-3 btn-style-3 inactive" style={{float: 'right', width: '50%'}} onClick={this.continue.bind(this)}>Siguiente</a>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepEight;