import React, { Component } from 'react';
import { Tools } from '../../tools';

class Header extends Component {

    constructor(props) {
        super(props);
        
        this.state = {
            url_vars: ''
        }
    }

    componentDidMount() {
        let _root = this;

        _root.setState({
            url_vars: Tools.getAllUrlVars()
        });
    }

    render() {
        const { logo } = this.props;
        const { url_vars } = this.state;

        return (
            <div className="container header-main">
                <div className="row">
                    <div className="col-sm-12 pt-3 pb-2">
                        <a href={URLPREFIX + '/' + url_vars}>
                            <img className="logo" src={logo} />
                        </a>
                    </div>
                </div>
            </div>
        )
    }

}

export default Header;