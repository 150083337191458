import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import CuboArmado1 from '../../../assets/images/cubo-armado/cubo-armado-1.svg';
import CuboArmado2 from '../../../assets/images/cubo-armado/cubo-armado-2.svg';
import CuboArmado3 from '../../../assets/images/cubo-armado/cubo-armado-3.svg';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepTwentyEight extends Component {

    componentDidMount() {
        document.title = "StepTwentyEight";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="q-panel">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-sm-12 col-md-6">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-12">
                                            <h1 className="title-style-4 pl-5 pr-5">¿Como se vería este cubo armado?</h1>
                                        </div>
                                    </div>

                                    <div className="row justify-content-center mt-2">
                                        <div className="col-sm-12 col-md-8 image-responsive">
                                            <img src={CuboArmado1} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <div className="row justify-content-center">
                                        <div className="col-sm-8">
                                            <div className="btn-group style-1" role="group">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-6 cubos">
                                                            <img className="mb-5" src={CuboArmado2} />
                                                            <a href="/step-twentynine" className="btn btn-danger btn-lg btn-block btn-style-2">A</a>
                                                        </div>
                                                        <div className="col-sm-6 cubos">
                                                            <img className="mb-5" src={CuboArmado3} />
                                                            <a href="/step-twentynine" className="btn btn-danger btn-lg btn-block btn-style-2">B</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepTwentyEight;