import React, { Component } from 'react';
import Question from '../../models/questions';
import User from '../../models/user';
import { Tools } from '../../tools';

import BgMain from '../../../assets/images/bg-main-2.png';

class PageStepThirtyThree extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;

        this.state = {
            configuration: [],

            // assets view
            logo: '',
            image_view: '',

            title_view: ''
        }
    }

    componentDidMount() {
        document.title = "Visualizate | Descúbrelo";

        let _root = this;

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            Tools.timeOut( Number( _root.state.configuration[0].field_tiempo_sesion[0].value ) );
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }

            if( _root.state.configuration[0].field_imagenes_vistas.length > 0 ) {
                for( let item of _root.state.configuration[0].field_imagenes_vistas ) {
                    if( item.alt == 'view-descubrelo' ) {
                        _root.setState({
                            image_view: item.url
                        });
                    }
                }
            }

            if( _root.state.configuration[0].field_texto_vista_descubrelo.length > 0 ) {
                _root.setState({
                    title_view: _root.state.configuration[0].field_texto_vista_descubrelo[0].value
                });
            }
        });
    }

    render() {
        const {logo, image_view, title_view } = this.state;

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href={URLPREFIX + '/'}>
                                <img className="logo" src={logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container init-form" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-7 image-view">
                                <img className="img-fluid" src={image_view} />
                            </div>
                            <div className="col-sm-12 col-md-4 offset-md-1">
                                <h1 className="title-style-3 mb-2">{title_view}</h1>
                                <a href={URLPREFIX + '/step-thirtyfour'} type="button" className="btn btn-danger mt-5 pt-3 pb-3 btn-style-1">¡Descúbrelo!</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepThirtyThree;