import React, { Component } from 'react';
import OwlCarousel from 'react-owl-carousel2';
import { Tools } from '../../tools';
import Question from '../../models/questions';
import User from '../../models/user';

import BgMain from '../../../assets/images/bg-main-2.png';

class PageStepThirtyFour extends Component {

    constructor(props) {
        super(props);

        this.QS = new Question;
        this.US = new User;
        
        this.options = {
            items: 1,
            nav: false,
            rewind: true,
            autoplay: true
        };

        this.events = {
            onDragged: this.onDraggedEvent,
            onChanged: this.onChangedEvent
        };

        this.state = {
            configuration: [],

            // assets view
            logo: '',
            image_view: '',

            title_view: '',
            message_view: '',
            message_whatsapp: '',
            phone_whatsapp: '',

            campus: '',
            carreras_campus: [],
            profile_select_id: 0,
            profile_select_title: '',
            profile_select_message: ''
        }
    }

    componentDidMount() {
        document.title = "Visualizate | Agendar";

        let _root = this;

        

        _root.setState({
            configuration: _root.QS.getConfigurationFromLocal()
        }, function() {
            if( _root.state.configuration[0].field_logo.length > 0 ) {
                _root.setState({
                    logo: _root.state.configuration[0].field_logo[0].url
                });
            }

            if( _root.state.configuration[0].field_imagenes_vistas.length > 0 ) {
                for( let item of _root.state.configuration[0].field_imagenes_vistas ) {
                    if( item.alt == 'view-agendar' ) {
                        _root.setState({
                            image_view: item.url
                        });
                    }
                }
            }

            if( _root.state.configuration[0].field_titulo_agendar.length > 0 ) {
                _root.setState({
                    title_view: _root.state.configuration[0].field_titulo_agendar[0].value
                });
            }

            if( _root.state.configuration[0].field_mensaje_agendar.length > 0 ) {
                _root.setState({
                    message_view: _root.state.configuration[0].field_mensaje_agendar[0].value
                });
            }

            if( _root.state.configuration[0].field_mensaje_whatsapp.length > 0 ) {
                
                let _nc = '';
                let _pw = '';
                let _crr = [];
                let _crrd = [];
                let _aprofiles = JSON.parse(Tools.decryptrData( localStorage.getItem('desktop_selected_items') ));
                let _result = [];

                if( Array.isArray(_aprofiles) ) {
                    if(_aprofiles.length > 0) {
                        if(_aprofiles.length == 1) {
                            _result.push({profile: _aprofiles[0].profile.title, corrects: []});
                        }
                        else if(_aprofiles.length == 2) {
                            let _obj = {profile: '', corrects: []};
                            for(let _a of _aprofiles[0].answers) {
                                if(_a.is_option_correct == '1') {
                                    _obj.profile = _aprofiles[0].profile.title;
                                    _obj.corrects.push(_a.is_option_correct);
                                }
                            }
                            _result.push(_obj);
                            _obj = {profile: '', corrects: []};
                            for(let _a of _aprofiles[1].answers) {
                                if(_a.is_option_correct == '1') {
                                    _obj.profile = _aprofiles[1].profile.title;
                                    _obj.corrects.push(_a.is_option_correct);
                                }
                            }
                            _result.push(_obj);
                        }

                        const _max = _result.reduce(function(prev, current) {
                            return (prev.corrects.length > current.corrects.length) ? prev : current
                        });
        
                        _root.setState({
                            profile_select_title: _max.profile
                        }, function() {
                            let _pm = JSON.parse( Tools.decryptrData( localStorage.getItem('profiles_messages') ) );
                            for(let pm of _pm) {
                                if( pm.title == _root.state.profile_select_title ) {
                                    _root.setState({
                                        profile_select_id: pm.id,
                                        profile_select_message: pm.description
                                    }, function() {

                                        for(let c of JSON.parse(Tools.decryptrData(localStorage.getItem('campus')))) {
                                            if(c.code == Tools.decryptrData(localStorage.getItem('campus_selected'))) {
                                                Tools.showPreloader();
                                                c.carrers = [];
                                                _nc = c.title;
                                                _pw = c.phone;

                                                _root.QS.getCampusById(_root.QS.getTokenFromLocal(), c.id)
                                                .then(res => {
                                                    if( res.length > 0 ) {
                                                        c.carrers = res[0].carrers;

                                                        for(let _c of c.carrers) {
                                                            if(_c.profiles.length > 0) {
                                                                for(let _ca of _c.profiles) {
                                                                    if( _ca.id == _root.state.profile_select_id ) {
                                                                        _crrd.push(_c);
                                                                    }
                                                                }
                                                            }
                                                        }
                                
                                                        let map = _crrd.reduce(function(prev, cur) {
                                                            prev[`${cur.title}|${cur.url}`] = (prev[cur.title] || 0) + 1;
                                                            return prev;
                                                        }, {});
                                
                                                        let _cr = Object.keys(map);
                                
                                                        if(Array.isArray(_cr)) {
                                                            if(_cr.length > 0) {
                                                                for(let _ca of _cr) {
                                                                    let _it = _ca.split('|');
                                                                    _crr.push({title: _it[0], url: _it[1]});
                                                                }
                                
                                                                if(_crr.length > 3) {
                                                                    _crr = _root.QS.getRandomElementsFromArray(_crr, 3);
                                                                }
                                                            }
                                                        }

                                                        let _mw = (_root.state.configuration[0].field_mensaje_whatsapp[0].value).replace('[CAMPUS]', _nc);
                                                        _root.setState({
                                                            message_whatsapp: _mw,
                                                            phone_whatsapp: _pw,
                                                            carreras_campus: _crr
                                                        }, function() {
                                                            
                                                            let _cr = [];
                                                            for(let _c of _crr) {
                                                                _cr.push(_c.title);
                                                            }
                                        
                                                            let _pro = [];
                                                            for(let o of _aprofiles) {
                                                                _pro.push(o.title);
                                                            }
                                                            let _data_result = {
                                                                careers: _cr.join(' | '),
                                                                profiles: _root.state.profile_select_title
                                                                // profiles: _pro.join(' | ')
                                                            };

                                                            const leadUpdate = ()=>{

                                                                var myHeaders = new Headers();
                                                                myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
                                                                var urlencoded = new URLSearchParams();
                                                                urlencoded.append("personalidad", _data_result.profiles);
                                                                urlencoded.append("resultadoTest", _data_result.careers);    
                                                                urlencoded.append("ID", localStorage.getItem('ur'));
                                                                                                                         
                                                                var requestOptions = {
                                                                    method: 'POST',
                                                                    headers: myHeaders,
                                                                    body: urlencoded,
                                                                    redirect: 'follow'
                                                                    };
                                                                 fetch(_root.state.configuration[0].field_api_lead_register[0].value, requestOptions)
                                                                    .then(response => response.json())
                                                                    .then(result => {
                                                                        console.log(result)
                                                                        
                                                                        
                                                                    })
                                                                    .then(result => {
                                                                        
                                                                    })
                                                                    .catch(error => error.json());



                                                            }

                                                            leadUpdate();
                                                            _root.QS.sendResult(_data_result, _root.QS.getTokenFromLocal())
                                                            .then(res => {
                                                                let _answer_items_tosend = JSON.parse( Tools.decryptrData( localStorage.getItem('answer_items_tosend') ) );
                                                                return _root.QS.sendQuestionResult(_answer_items_tosend, _root.QS.getTokenFromLocal());
                                                            })
                                                            



                                                            .then(res => {
                                                                localStorage.setItem('answer_items_tosend', Tools.cryptrData( JSON.stringify([]) ));
                                                                Tools.hidePreloader();
                                                            })
                                                            .catch(err => {
                                                                Tools.hidePreloader();
                                                            });
                                                        });
                                                    }
                                                    else {
                                                        Tools.hidePreloader();
                                                    }
                                                })
                                                .catch(err => {
                                                    Tools.hidePreloader();
                                                    console.log( err );
                                                });
                                            }
                                        }

                                    });
                                }
                            }
                        });
                    }
                }
            }

            for(let c of JSON.parse(Tools.decryptrData(localStorage.getItem('campus')))) {
                if(c.code == Tools.decryptrData(localStorage.getItem('campus_selected'))) {
                    _root.setState({
                        campus: `${c.title}`
                    });
                }
            }
        });
    }

    onDraggedEvent(event) {
        
    }

    onChangedEvent(event) {

    }

    render() {
        const {logo, image_view, title_view, message_view, message_whatsapp, phone_whatsapp, carreras_campus, campus, profile_select_title, profile_select_message } = this.state;
        const _wurl = ( Tools.platformDevice() == 'web' ) ? 'https://web.whatsapp.com/' : 'https://api.whatsapp.com/';

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href={URLPREFIX + '/'}>
                                <img className="logo" src={logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container init-form" style={{backgroundImage: 'url(' + URLPREFIX + BgMain + ')'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-7 image-view">
                                <img className="img-fluid" src={image_view} />
                            </div>
                            <div className="col-sm-12 col-md-4 offset-md-1">
                                <h1 className="title-style-3 mb-2" dangerouslySetInnerHTML={{__html: title_view}}></h1>
                                <div>
                                    <OwlCarousel options={this.options} events={this.events} >
                                        <div className="item">
                                            <h1 className="title-style-1 mt-5 mb-2 text-danger profile-title-selected">{profile_select_title}</h1>
                                            <p className="parra-style-3" dangerouslySetInnerHTML={{__html: profile_select_message}}>
                                            </p>
                                        </div>
                                        <div className="item">
                                            <h1 className="title-style-1 mt-5 mb-2 text-danger">Carreras UTP que te pueden interesar</h1>
                                            <p className="parra-style-3">
                                                <ul className="list-result-carrers">
                                                    {
                                                        (carreras_campus.length > 0)
                                                        ?
                                                        carreras_campus.map((item, i) => (
                                                            <li key={i}><a className="url-carrer" href={item.url} target="_blank">{item.title}</a></li>
                                                        ))
                                                        :
                                                        ''
                                                    }
                                                </ul>
                                            </p>
                                        </div>
                                    </OwlCarousel>
                                </div>
                                <p className="parra-style-3">{message_view}</p>
                                <h1 className="title-style-6">{campus}</h1>
                                <a target="_blank" href={`${_wurl}send?phone=051${phone_whatsapp}&text=${message_whatsapp}&source=&data=`} type="button" class="btn btn-danger pt-3 pb-3 btn-style-1">Contáctanos</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepThirtyFour;