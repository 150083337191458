import { isIOS, isAndroid, isChrome, isFirefox, isIE } from "react-device-detect";
import CryptoJS from 'crypto-js';
import toastr from 'toastr';

export const Tools = {

    platformDevice: function() {
        let _data = 'web';
        if( isAndroid ) {
            _data = 'mobile';
        }
        else if( isIOS ) {
            _data = 'mobile';
        }
        else if( isChrome ) {
            _data = 'web';
        }
        else if( isFirefox ) {
            _data = 'web';
        }
        else if( isIE ) {
            _data = 'web';
        }
        else{
            _data = 'web';
        }
        return _data;
    },

    showPreloader: function() {
        if( document.getElementById('preloader') ) {
            document.getElementById('preloader').style.display = 'flex';
        }
    },

    hidePreloader: function() {
        setTimeout(() => {
            document.getElementById('preloader').style.display = 'none';
        }, 1000);
    },

    validateEmail: function(value) {
        let response = false;
        let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validate = regex.test(value);
        if( validate ) {
            response = true;
        }

        return response;
    },

    validateOnlyLetter: function(value) {
        let response = false;
        let regex = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ]+$/i;
        let validate = regex.test(value);
        if( validate ) {
            response = true;
        }

        return response;
    },

    validateCellPhoneNumber: function(value) {
        let response = false;
        let regex = /\d{9}/;
        let validate = regex.test(value);
        if( validate ) {
            if( value.length == 9 ) {
                if( value[0] == 9 || value[0] == '9' ) {
                    response = true;
                }
            }
        }

        return response;
    },

    validateDocumentNumber: function(value, type = 'dni') {
        let response = false;
        let regexnumber = /\d{8}/;
        let regexalphanumber = /^[0-9]+$/i;

        if( type == 'dni' && value.length == 8 ) {
            let validate = regexnumber.test(value);
            if( validate ) {
                response = true;
            }
        }
        else if( type == 'pasaporte' && value.length == 9 ) {
            let validate = regexalphanumber.test(value);
            if( validate ) {
                response = true;
            }
        }
        else if( type == 'carnet' && value.length == 9 ) {
            let validate = regexalphanumber.test(value);
            if( validate ) {
                response = true;
            }
        }
        else if( type == 'ruc' && value.length == 11 ) {
            let validate = regexnumber.test(value);
            if( validate ) {
                response = true;
            }
        }

        return response;
    },

    cryptrData: function(data) {
        // ENCRYPT DATA
        // let encrypt_data = CryptoJS.AES.encrypt(data, 'visualizate-utp').toString();
        // return encrypt_data;

        return data;
    },

    decryptrData: function(data) {
        // DECRYPT DATA
        // let decrypt_data = CryptoJS.AES.decrypt(data.toString(), 'visualizate-utp');
        // let decrypt_tostring = decrypt_data.toString(CryptoJS.enc.Utf8);
        // return decrypt_tostring;

        return data;
    },

    showToastr: function(title, message, type, time = 1500) {
        toastr.options.closeButton = true;
        toastr.options.positionClass = 'toast-top-center';
        toastr.options.timeOut = time;
        switch (type) {
            case 'success':
                toastr.success(title, message);
                break;
            case 'error':
                toastr.error(title, message);
                break;
            case 'warning':
                toastr.warning(title, message);
                break;
        }
    },

    timeOut: function(timeout, vars = '') {
        var t;
        var time = 0;
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;    
        window.ontouchstart = resetTimer;
        window.onclick = resetTimer;
        window.onkeypress = resetTimer;   
        window.addEventListener('scroll', resetTimer, true); 

        function resetTimer() {
            clearTimeout(t);
            time = 0;
            t = setInterval(function() {
                time = time + 1;
                if(time == timeout) {
                    window.location.href = `${URLPREFIX}/${vars}`;
                }
            }, 1000);
        }
    },

    getUrlVars: function() {
        var vars = {};
        var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    },
    getUTM: function(name){
            name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
            var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
            return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    },
    getAllUrlVars: function() {
        let queryString = window.location.search;
        return queryString;
    },

    extractHostname(url) {
        var hostname;
        //find & remove protocol (http, ftp, etc.) and get hostname
    
        if (url.indexOf("//") > -1) {
            hostname = url.split('/')[2];
        }
        else {
            hostname = url.split('/')[0];
        }
    
        //find & remove port number
        hostname = hostname.split(':')[0];
        //find & remove "?"
        hostname = hostname.split('?')[0];
    
        return hostname;
    },

    extractProtocol(url) {
        var arr = (url).split(':');
        return arr[0];
    }
}