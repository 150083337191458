import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import Logo from '../../../assets/images/logo.svg';
import BgMain from '../../../assets/images/bg-main-2.png';

import SigueSec1 from '../../../assets/images/sigue-secuencia-2/sigue-sec-1-2.svg';
import SigueSec2 from '../../../assets/images/sigue-secuencia-2/sigue-sec-2-2.svg';
import SigueSec3 from '../../../assets/images/sigue-secuencia-2/sigue-sec-3-2.svg';
import SigueSec4 from '../../../assets/images/sigue-secuencia-2/sigue-sec-4-2.svg';
import SigueSec5 from '../../../assets/images/sigue-secuencia-2/sigue-sec-5-2.svg';

// Actions-
import { exampleAction } from '../../actions/exampleActions'

@connect((store) => {
    return {
        example: store.example,
    }
})
class PageStepThirtyOne extends Component {

    componentDidMount() {
        document.title = "StepThirtyOne";
    }

    render() {
        if(!this.props.example) {

            this.props.dispatch(exampleAction(true));
        }

        return (
            <div>
                <div className="container header-main">
                    <div className="row">
                        <div className="col-sm-12 pt-3 pb-2">
                            <a href="/">
                                <img className="logo" src={Logo} />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="main-container center" style={{backgroundImage: 'url(' + BgMain + ')'}}>
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-sm-12 col-md-7">
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <h1 className="title-style-4 pl-5 pr-5">Sigue la secuencia</h1>
                                    </div>
                                </div>

                                <div className="row justify-content-center mt-2">
                                    <div className="col-sm-12 col-md-8 image-responsive">
                                        <img src={SigueSec1} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-5">
                                <div className="row justify-content-center">
                                    <div className="col-sm-8">
                                        <div className="btn-group style-1" role="group">
                                            <div className="container">
                                                <div className="row align-items-end pt-5">
                                                    <div className="col-sm-12 col-md-6 mb-5 objecto sec">
                                                        <img className="mb-4" src={SigueSec2} />
                                                        <a href="/step-thirtytwo" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">1</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-5 objecto sec">
                                                        <img className="mb-4" src={SigueSec3} />
                                                        <a href="/step-thirtytwo" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">2</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-5 objecto sec">
                                                        <img className="mb-4" src={SigueSec4} />
                                                        <a href="/step-thirtytwo" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">3</a>
                                                    </div>
                                                    <div className="col-sm-12 col-md-6 mb-5 objecto sec">
                                                        <img className="mb-4" src={SigueSec5} />
                                                        <a href="/step-thirtytwo" className="btn btn-danger btn-lg btn-block btn-style-5 pt-4 pb-4">4</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PageStepThirtyOne;