import { Tools } from "./tools";

const DRUPAL_USER = USERREST;
const DRUPAL_PASSWORD = USERPASS;
const DRUPAL_CREDENTIALS = btoa(`${DRUPAL_USER}:${DRUPAL_PASSWORD}`);

const URL_API = URL;

export const APIS = {
    // CONFIGURATION API
    configuration: `${URL_API}/rest/configuration?_format=json`,

    // CREDENTIALS
    token: `${URL_API}/rest/session/token`,
    basic_auth: `Basic ${DRUPAL_CREDENTIALS}`,

    // USER APIS
    user_register: `${URL_API}/rest/registrant?_format=hal_json`,

    // QUESTION APIS
    question_group_list: `${URL_API}/rest/question_group_list/`,
    question_group: `${URL_API}/rest/question_group/`,
    answer: `${URL_API}/rest/answer?_format=hal_json`,
    result: `${URL_API}/rest/answer/`,
    question_answers: `${URL_API}/rest/answer/`,

    // CAMPUS APIS
    campus_list: `${URL_API}/rest/campus?_format=hal_json`,
    campus_byid: `${URL_API}/rest/campus/`,

    // GRUPO COLEGIOS
    grupo_colegios: `${URL_API}/crmrest/cuentas/group/`,

    // CRM
    naturalezas: `${URL_API}/crmrest/naturalezas`,
    distritos: `${URL_API}/crmrest/distrito/`,
}